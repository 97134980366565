import React, { useRef, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";

const ReasonButtons = (props) => {
  const _reason = useRef(props.reason);

  useEffect(() => {
    _reason.current.value = props.reason;
  }, [props.reason]);
  const addReason = (e) => {
    e.preventDefault();
    let value = e.target.value;
    if (_reason.current.value !== "") {
      _reason.current.value = _reason.current.value + ", " + value;
    } else {
      _reason.current.value = value;
    }
    props.handleReasonChanged(_reason.current.value);
  };
  const handleChange = (event) => {
    const { value } = event.target;
    props.handleReasonChanged(value);
  };

  return (
    <>
      <Row className="align-items-baseline">
        <Col sm="2">
          <h4>Anledning:</h4>
        </Col>
        <Col>
          <div className="form-group">
            <input
              ref={_reason}
              className="form-control"
              placeholder="Anledning"
              name="reason"
              onChange={handleChange}
              onKeyPress={props.onKeyPress}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            id="flackButton"
            name="flackButton"
            className="btn-info btn-block"
            size="sm"
            value="fläck"
            onClick={addReason}
          >
            Fläck
          </Button>
        </Col>
        <Col>
          <Button
            id="saknarStorlekButton"
            name="saknarStorlekButton"
            className="btn-info btn-block"
            size="sm"
            value="saknar storlek"
            onClick={addReason}
          >
            Saknar storlek
          </Button>
        </Col>
        <Col>
          <Button
            id="urtvättadButton"
            name="urtvättadButton"
            className="btn-info btn-block"
            size="sm"
            value="urtvättad"
            onClick={addReason}
          >
            Urtvättad
          </Button>
        </Col>
        <Col>
          <Button
            id="nopprigButton"
            name="nopprigButton"
            className="btn-info btn-block"
            size="sm"
            value="nopprig"
            onClick={addReason}
          >
            Nopprig
          </Button>
        </Col>
        <Col>
          <Button
            id="trasigButton"
            name="trasigButton"
            className="btn-info btn-block"
            size="sm"
            value="trasig"
            onClick={addReason}
          >
            Trasig
          </Button>
        </Col>
      </Row>
      <Row className="row-margin">
        <Col>
          <Button
            id="slitetButton"
            name="slitetButton"
            className="btn-info btn-block"
            size="sm"
            value="slitet"
            onClick={addReason}
          >
            Slitet
          </Button>
        </Col>
        <Col>
          <Button
            id="ejKomplettButton"
            name="ejKomplettButton"
            className="btn-info btn-block"
            size="sm"
            value="ej komplett"
            onClick={addReason}
          >
            Ej komplett
          </Button>
        </Col>
        <Col>
          <Button
            id="naggButton"
            name="naggButton"
            className="btn-info btn-block"
            size="sm"
            value="nagg"
            onClick={addReason}
          >
            Nagg
          </Button>
        </Col>
        <Col>
          <Button
            id="krackeleradButton"
            name="krackeleradButton"
            className="btn-info btn-block"
            size="sm"
            value="krackelerad"
            onClick={addReason}
          >
            Krackelerad
          </Button>
        </Col>
        <Col>
          <Button
            id="osaljbartButton"
            name="osaljbartButton"
            className="btn-danger btn-block"
            size="sm"
            value="ej säljbart"
            onClick={addReason}
          >
            ej säljbart
          </Button>
        </Col>
      </Row>
      <Row className="row-margin">
        <Col>
          <Button
            id="avvisatButton"
            name="avvisatButton"
            className="btn-info btn-block"
            size="sm"
            value="Avvisat av miljö och etiska skäl"
            onClick={addReason}
          >
            avvisat av miljö och etiska skäl
          </Button>
        </Col>
        <Col>
          <Button
            id="osaltButton"
            name="osaltButton"
            className="btn-info btn-block"
            size="sm"
            value="Osålt efter 8 månader"
            onClick={addReason}
          >
            osålt efter 8 månader
          </Button>
        </Col>
      </Row>
    </>
  );
};
export default ReasonButtons;
