import React from "react";
import Table from "react-bootstrap/Table";
function FixDate(date) {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split("T")[0];
}
const SoldInSello = (props) => {
  const returnedProductsNotPaid = props.returnedProducts.filter((item) => {
    return item.paid !== true;
  });
  const soldProductsNotPaid = props.sold;
  const doljForsaljningspris = props.doljForsaljningspris;
  return (
    <div>
      <h4>Sålda saker</h4>
      <br />
      <Table responsive size="sm">
        <tbody>
          <tr>
            <th>Antal</th>
            <th>Produkt</th>
            {!doljForsaljningspris && <th>Pris</th>}
            <th>Din andel</th>
          </tr>
          {soldProductsNotPaid.map(function (item) {
            return (
              <tr key={item.id}>
                <td> {item.quantity} st.</td>
                <td>{item.product_title}</td>
                {!doljForsaljningspris && (
                  <td>
                    {" "}
                    {props.kundtyp === "Privatperson"
                      ? item.sumVat.toFixed(2)
                      : item.sumExVat.toFixed(2)}{" "}
                    kr
                  </td>
                )}
                <td> {item.earnings.toFixed(2)} kr</td>
              </tr>
            );
          })}
          {returnedProductsNotPaid.map((item) => {
            let date = new Date(item.createdAt);
            const dateString = FixDate(date);
            return (
              <tr key={item.id}>
                <td> {dateString}</td>
                <td>{item.description}</td>
                <td>{item.price.toFixed(2)} kr</td>
                <td>- {item.earning.toFixed(2)} kr</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};
export default SoldInSello;
