import React from "react";
import Table from "react-bootstrap/Table";

export default function Purchases(props) {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const adjustedDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000,
    );
    return adjustedDate.toISOString().split("T")[0];
  };
  const purchases = props.purchases;
  return (
    <div>
      <br />
      <Table responsive size="sm">
        <thead>
          <tr>
            <th>Datum</th>
            <th>Produkter</th>
            <th>Summa</th>
          </tr>
        </thead>
        <tbody>
          {purchases.map((purchase) => {
            return (
              <React.Fragment key={purchase.id}>
                <tr>
                  <td>{formatDate(purchase.purchaseDate)}</td>
                  <td
                    dangerouslySetInnerHTML={{
                      __html: purchase.products.replace(/kr\.,/g, "kr.<br />"),
                    }}
                  />
                  <td>{`${purchase.totalSum} kr`}</td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}
