import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";

function PrivateRoute({ component: Component, roles, auth, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth.isAuthenticated && auth.roles[roles] === false) {
          return (
            <h3>
              Access saknas, du behöver följande roles: <br /> {roles}
            </h3>
          );
        }

        if (auth.isAuthenticated) return <Component auth={auth} {...props} />;
      }}
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  scopes: PropTypes.array,
};

PrivateRoute.defaultProps = {
  scopes: [],
};

export default PrivateRoute;
