import React, { useState, useEffect } from "react";
import { getCustomerByCustomerNumber } from "../Helpers/CustomerInfo";
import { Row, Col, Button } from "react-bootstrap";
import { IsLoadingButton } from "../components/LoadingSpinner";
import AddPurchase from "../components/Administration/PurchaseOrder/AddPurchase";

function CreatePurchaseOrder(props) {
  const [customerNumber, setCustomerNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [customerInformation, setCustomerInformation] = useState();

  useEffect(() => {
    async function fetchAccessToken() {
      const accessToken = await props.auth.getAccessTokenSilently();
      setAccessToken(accessToken);
    }
    fetchAccessToken();
  }, [props.auth]);

  const handleCustomerNumberChange = (e) => {
    setCustomerNumber(e.target.value);
  };

  const onKeyPress = (e) => {
    if (e.charCode === 13) {
      searchkundnummer(e);
    }
  };
  const searchkundnummer = (e) => {
    setStatus("");
    setShowForm(false);
    setIsLoading(true);

    e.preventDefault();
    getKundnr(customerNumber);
  };

  const getKundnr = async (customerNumber) => {
    let response = await getCustomerByCustomerNumber(
      customerNumber,
      accessToken,
    );
    let kund = response.data.kund;
    let customerInformation = {
      CustomerId: kund.id,
      CustomerNumber: kund.Kundnr,
      CustomerName: `${kund.Förnamn} ${kund.Efternamn}`,
      CustomerAdress: `${kund.Adress}, ${kund.Postnummer}, ${kund.Postort}`,
      CustomerEmail: kund.Epost,
      CustomerType: kund.Kundtyp,
    };
    setCustomerInformation(customerInformation);
    setIsLoading(false);
    if (kund.Kundtyp !== "Privatperson") {
      setStatus("Detta är en företagskund, inköpsorder är inte möjligt här");
    } else setShowForm(true);
  };

  return (
    <div className="call-to-action">
      <h2>Skapa inköpsorder</h2>
      <Row>
        <Col xs={6}>
          <div className="form-group">
            <input
              id="kundnummerInput"
              name="kundnr"
              type="text"
              value={customerNumber}
              onChange={handleCustomerNumberChange}
              placeholder="inlämningsnummer"
              className="form-control"
              onKeyPress={onKeyPress}
            />
          </div>
        </Col>
        <Col xs={2}>
          <Button
            className="btn-footly"
            id="searchButton"
            name="searchButton"
            onClick={searchkundnummer}
          >
            {isLoading && <IsLoadingButton />}
            Sök
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>{status}</Col>
      </Row>
      {showForm ? (
        <AddPurchase customerInformation={customerInformation} />
      ) : null}
    </div>
  );
}

export default CreatePurchaseOrder;
