import React, { Component } from "react";
import { Button } from "react-bootstrap";
import CallApi from "./CallApi";
import { IsLoadingButton } from "./LoadingSpinner";

import { getCustomer } from "../Helpers/CustomerInfo";
import { Link } from "react-router-dom";
class RegisterDropOff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      message: "",
      kund: null,
      disabled: true,
      dropOffs: [],
    };
  }
  async componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      return;
    }
    const accessToken = await this.props.auth.getAccessTokenSilently();
    const kundres = await getCustomer(this.props.auth.user, accessToken);
    console.log("kundres: ", kundres);

    if (kundres.status === 200) {
      this.setState({ kund: kundres.data.kund });
      if (kundres.data.kund !== null && kundres.data.kund.Kundtyp !== "") {
        this.setState({
          disabled: false,
          dropOffs: kundres.data.kund.DropOffs,
        });
      }
    } else {
      this.setState({
        message: "Något gick fel, försök igen eller kontakta Footly",
      });
    }
  }

  registerDropOff = async () => {
    this.setState({ isLoading: true, disabled: true });
    var previousDropOff = this.state.dropOffs.filter((dropoff) => {
      if (dropoff.handledDate === null) return dropoff;
      return null;      
    });
    if (previousDropOff.length) {
      this.setState({
        message: "Du har redan ett inlämningstillfälle registrerat.",
      });
    } else {
      const body = {
        dropOffDate: new Date(),
        KundId: this.state.kund.id,
      };
      try {
        const res = await CallApi(
          "Post",
          `/dropoff`,
          body,
          this.props.accessToken
        );
        if (res.status === 201) {
          this.setState({
            message: "Inlämningstillfälle registrerat!",
          });
        }
      } catch (error) {
        this.setState({
          message: "Något gick fel, försök igen eller kontakta Footly",
        });
      }
      //2022-06-07 - Disabled the mail to customer when dropoff is registred
      //await this.sendMailToCustomer();
    }
    this.setState({ isLoading: false });
  };
  sendMailToCustomer = async () => {
    const body = {
      recipient: this.state.kund.Epost,
      kundnr: this.state.kund.Kundnr,
    };
    await CallApi(
      "Post",
      `/dropoffregistredmail`,
      body,
      this.props.accessToken
    );
  };
  render() {
    return (
      <div className="call-to-action">
        <h2>Registrera inlämning</h2>
        {this.state.kund !== null && !this.state.disabled && (
          <p>
            Lägg en lapp i varje kartong med ditt inlämningsnummer:{" "}
            <b>{this.state.kund.Kundnr}</b> <br />
            Tryck sedan nedan för att registrera din inlämning.
            <br />
          </p>
        )}
        {this.state.kund !== null && this.state.kund.Kundtyp === "" && (
          <p>
            <b>
              Du måste först välja om du är privatperson eller ett företag innan
              du registrerar en inlämning. Det kan du göra
              <Link to="profile"> här</Link>
            </b>
          </p>
        )}
        {this.state.kund === null && (
          <p>
            Du måste registrera dig först för att kunna lämna in. Tryck på Logga
            in och registrera nytt konto.
          </p>
        )}
        <Button
          //   className="btn-footly"
          id="registerDropOffButton"
          name="registerDropOffButton"
          onClick={this.registerDropOff}
          disabled={this.state.disabled}
        >
          {this.state.isLoading && <IsLoadingButton />}
          Registrera inlämning
        </Button>

        <h4>{this.state.message}</h4>
      </div>
    );
  }
}
export default RegisterDropOff;
