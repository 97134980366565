import React from "react";
import Table from "react-bootstrap/Table";

const Summary = (props) => {
  return (
    <div>
      {/* <h4>Sammanställning</h4> */}
      <br />
      <i>Sammanställning sedan senaste utbetalningen</i>
      {}
      <Table responsive size="sm">
        <tbody>
          <tr key="sold">
            <td
              className="summary-headings"
              onClick={() => props.onClick("sold")}
            >
              Sålda saker
            </td>
            <td>{props.soldItems} st</td>
            <td>+ {props.sumEarnings.toFixed(2)} kr</td>
          </tr>
          {/* <tr>
          <td onClick={() => props.onClick("charity")}>Välgörenhet</td>
          <td>X st</td>
          <td>+ X:-</td>
        </tr> */}
          <tr>
            <td
              className="summary-headings"
              onClick={() => props.onClick("dropoffs")}
            >
              Inlämningar
            </td>
            <td>{props.dropOffNoItems} st</td>
            <td>- {props.dropOffCost.toFixed(2)} kr</td>
          </tr>
          {props.purchases.length > 0 && (
            <tr>
              <td
                className="summary-headings"
                onClick={() => props.onClick("purchases")}
              >
                Sålt till Footly
              </td>
              <td></td>
              <td>
                + {(Math.round(props.totalPurchasesSum * 100) / 100).toFixed(2)}{" "}
                kr
              </td>
            </tr>
          )}
          <tr>
            <td>Saldo</td>
            <td />
            <td>{props.balance.toFixed(2)} kr</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};
export default Summary;
