import React, { useEffect, useState } from "react";
import { Row, Col, Button, ListGroup } from "react-bootstrap";
import CallApi from "../../CallApi";

const AddPurchase = (props) => {
  const [product_title, setProduct_title] = useState("");
  const [amount, setAmount] = useState("");
  const [status, setStatus] = useState("");
  const [sum, setSum] = useState(0);
  const [showSum, setShowSum] = useState(false);
  const [postPurchase, setPostPurchase] = useState(false);
  const [items, setItems] = useState([]);
  const [customerInformation, setCustomerInformation] = useState();

  useEffect(() => {
    setCustomerInformation(props.customerInformation);
    setPostPurchase(false);
  }, [props.customerInformation]);

  const addPurchase = () => {
    //remove decimals from amount
    const wholeAmount = Math.floor(parseFloat(amount));
    setSum(sum + wholeAmount);
    const newItem = {
      product_title,
      amount: wholeAmount,
      price: wholeAmount + " kr.",
      vat: "0%",
      vatAmount: 0 + " kr.",
    };
    setItems([...items, newItem]);
    setShowSum(true);
    setAmount("");
    setProduct_title("");

    const apparelControl = document.getElementById("apparel");
    apparelControl.focus();
  };

  const handleProduct_titleChange = (e) => {
    setProduct_title(e.target.value);
  };
  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const completePurchase = async () => {
    const totalAmount = items.reduce(
      (accumulator, item) => accumulator + Number(item.amount),
      0,
    );
    const body = {
      Date: new Date().toISOString().split("T")[0],
      Buyer: {
        CustomerId: customerInformation.CustomerId,
        CustomerNumber: customerInformation.CustomerNumber,
        Name: customerInformation.CustomerName,
        Address: customerInformation.CustomerAdress,
        Email: customerInformation.CustomerEmail,
      },
      Total: totalAmount,
      ArticleInformation: items,
    };
    setPostPurchase(true);
    const result = await CallApi(
      "POST",
      "/manualPurchase",
      body,
      props.accessToken,
    );
    result.status === 200
      ? setStatus("Inköpsorder skapad")
      : setStatus("Något gick fel, kontrollera loggarna eller försök igen");
  };

  const handleDeleteItem = (index) => {
    const updatedItems = [...items];
    setSum(sum - Number(updatedItems[index].amount));
    setShowSum(index !== 0);
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      addPurchase();
    }
  };

  return (
    <>
      <Row>
        <Col>
          <p>
            {props.customerInformation.CustomerName},{" "}
            {props.customerInformation.CustomerEmail}
          </p>
          <hr />
        </Col>
      </Row>

      <Row className="align-items-baseline">
        <Col>
          <h4>Vara:</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <input
            type="text"
            className="form-control"
            id="apparel"
            placeholder="Vara"
            value={product_title}
            onChange={handleProduct_titleChange}
            onKeyPress={handleKeyPress}
          />
        </Col>
        <Col>
          <input
            type="text"
            id="pris"
            className="form-control"
            placeholder="Pris"
            value={amount}
            onChange={handleAmountChange}
            onKeyPress={handleKeyPress}
          />
        </Col>

        <Col>
          <div className="form-group">
            <Button
              className="btn-block btn-footly"
              id="addButton"
              name="addButton"
              type="submit"
              onClick={addPurchase}
            >
              Lägg till
            </Button>
          </div>
        </Col>
      </Row>
      <ListGroup className="trash-items">
        {items.map((item, index) => (
          <ListGroup.Item key={index}>
            {item.product_title} {item.amount} kr.
            <button
              type="button"
              className="close"
              onClick={() => {
                handleDeleteItem(index);
              }}
            >
              &times;
            </button>
          </ListGroup.Item>
        ))}
      </ListGroup>
      {showSum && (
        <Row>
          <Col className="text-right margin-bottom">Totalsumma: {sum} kr.</Col>
        </Row>
      )}
      <Row>
        <Col>
          <div className="form-group">
            <Button
              className="btn-block btn-footly"
              id="completeButton"
              name="completeButton"
              type="submit"
              onClick={completePurchase}
              disabled={postPurchase}
            >
              Slutför inköp
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4>{status}</h4>
        </Col>
      </Row>
    </>
  );
};
export default AddPurchase;
