import { Spinner } from "react-bootstrap";
import * as React from "react";

class LoadingSpinner extends React.Component {
  render() {
    return (
      <div
        className="d-flex align-items-top justify-content-center"
        id="spinner"
      >
        <Spinner animation="border" role="status">
          <span className="sr-only">Laddar...</span>
        </Spinner>
      </div>
    );
  }
}
function IsLoading() {
  return (
    <Spinner animation="border" role="status" aria-hidden="true">
      <span className="sr-only">Laddar...</span>
    </Spinner>
  );
}
function IsLoadingButton() {
  return (
    <Spinner animation="border" role="status" aria-hidden="true" size="sm">
      <span className="sr-only">Laddar...</span>
    </Spinner>
  );
}
export { IsLoading, LoadingSpinner, IsLoadingButton };
