import CallApi from "../components/CallApi";
import { CalculateEarnings } from "../Helpers/CalculatePriceList";

const getCustomer = async (userId, authToken) => {
  const apiUrl = `/kund/${userId.sub.replace("auth0|", "")}`;
  const response = await CallApi("GET", apiUrl, "", authToken);
  return response;
};

const getCustomers = async (token) => {
  const response = await CallApi("Get", "/kund", "", token);
  return response;
};

const getCustomersWithEarnings = async (token) => {
  const response = await CallApi("Get", "/earnings", "", token);
  return response;
};

const getCustomerByCustomerNumber = async (customernumber, token) => {
  const response = await CallApi(
    "get",
    `/kund/customernumber/${customernumber}`,
    "",
    token,
  );
  return response;
};
const getCustomerById = async (id, token) => {
  const res = await CallApi("Get", `/kund/${id}`, "", token);
  return res;
};
const getCustomerByEmail = async (email, token) => {
  const response = await CallApi("get", `/kund/email/${email}`, "", token);
  return response;
};
const getSoldProductsForCustomer = async (kundnr, token) => {
  if (typeof process.env.REACT_APP_ACCOUNT !== "undefined") {
    kundnr = process.env.REACT_APP_ACCOUNT;
  }
  const res = await CallApi("Get", `/sold/${kundnr}`, "", token);
  return res;
};

const calculateCustomerBalance = async (
  pricelist,
  products,
  returnedProducts,
  customerType,
  dropOffs,
) => {
  let balance = 0;

  if (!pricelist) {
    return balance;
  }

  const earningsResult = await CalculateEarnings(
    pricelist,
    products.data.sold,
    returnedProducts,
    customerType,
  );
  const sumEarnings = earningsResult.sumEarnings;

  const dropOffCost = dropOffs.reduce((acc, element) => {
    if (element.handledDate && !element.paid) {
      return acc + element.cost + element.pickupCost;
    }
    return acc;
  }, 0);

  balance = sumEarnings - Math.abs(dropOffCost);

  return balance;
};

export {
  getCustomer,
  getCustomers,
  getCustomerByCustomerNumber,
  getCustomerByEmail,
  getCustomerById,
  getSoldProductsForCustomer,
  calculateCustomerBalance,
  getCustomersWithEarnings,
};
