import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { IsLoadingButton } from "../LoadingSpinner";
import CallApi from "../CallApi";

const LatestSync = (props) => {
  const [latestSyncDate, setLatestSyncDate] = useState(null);
  const [isSyncing, setIsSyncing] = useState(false);
  const [syncError, setSyncError] = useState(null);

  const getLatestSyncDate = useCallback(async () => {
    try {
      const res = await CallApi("Get", "/latestsync", null, props.accessToken);
      setLatestSyncDate(res.data.date);
    } catch (error) {
      console.error("Failed to get latest sync date:", error);
      setSyncError(error);
    }
  }, [props.accessToken]);

  useEffect(() => {
    getLatestSyncDate();
  }, [getLatestSyncDate]);

  const startsync = async () => {
    try {
      console.log("startsync called"); // Consider removing for production
      setIsSyncing(true);
      await CallApi("Get", "/startsyncadmin", null, props.accessToken);
    } catch (error) {
      console.error("Sync failed:", error);
      setSyncError(error);
    } finally {
      setIsSyncing(false);
    }
  };

  return (
    <>
      <Row>
        <Col>
          {latestSyncDate && (
            <h4>
              Senaste synkning mot Sello:{" "}
              {new Date(latestSyncDate).toLocaleString()}
            </h4>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {syncError && (
            <div className="error-message">Error: {syncError.message}</div>
          )}
          <Button name="sync-button" onClick={startsync} disabled={isSyncing}>
            {isSyncing ? <IsLoadingButton /> : "Synka mot Sello"}
          </Button>
        </Col>
      </Row>
    </>
  );
};
export default LatestSync;
