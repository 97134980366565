import React from "react";
import { NotLoggedIn } from "./NotLoggedIn";
import LoggedIn from "./LoggedIn";

import { useAuth0 } from "@auth0/auth0-react";

const Home = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <React.Fragment>
      <div className="call-to-action">
        <div className="text-center">
          <h1>Välkommen!</h1>
          {isAuthenticated ? <LoggedIn /> : <NotLoggedIn />}
        </div>
      </div>
    </React.Fragment>
  );
};
export default Home;
