const axios = require("axios");
async function CallApi(method, endpoint, body, accessToken) {
  let response = null;
  try {
    if (body !== "") {
      response = await axios(`${process.env.REACT_APP_API_URL}${endpoint}`, {
        method,
        data: body,
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${accessToken}`,
        },
      });
    } else {
      response = await axios(`${process.env.REACT_APP_API_URL}${endpoint}`, {
        method,
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${accessToken}`,
        },
      });
    }

    return response;
  } catch (error) {
    console.log(error.response.status);
    console.error(error);
    response = error.response;
  } finally {
    return response;
  }
}
export default CallApi;
