import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import CallApi from "./CallApi";
import { StartIcons } from "./StartIcons";

const LoggedIn = () => {
  const [state, setState] = useState({
    accessToken: false,
    kund: "",
    kundnummer: "",
    kundtyp: "",
  });
  const { user, getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    async function Initialize() {
      const token = await getAccessTokenSilently();
      
      let kund = await accountExists(token);
      setState({
        ...state,
        kundnummer: kund.Kundnr,
        kundtyp: kund.Kundtyp,
        kund: kund,
        accessToken: token,
      });
      return kund;
    }
    async function accountExists(accessToken) {
      let kund;
      var res = await checkUserAccount(user.email, accessToken);
      if (res.data.exists === false) {
        console.log("Creating new account");
        const newkundres = await createUserAccount(accessToken);
        kund = newkundres.data.kund;
      } else {
        kund = res.data.kund;
      }
      console.log("Kund: ", kund);
      return kund;
    }
    const checkUserAccount = async (email, accessToken) => {
      var response = await CallApi(
        "Get",
        `/kund/email/${email}`,
        "",
        accessToken
      );
      return response;
    };

    const createUserAccount = async (accessToken) => {
      const kundnr = await CallApi("GET", "/newkundnr", "", accessToken);
      const kundtyp = user['https://my.footly.se/kundtyp'].kundtyp;
      const PricelistId = kundtyp === "Privatperson" ? 1 : 2;
      if (kundnr.data.maxnumber === null) kundnr.data.maxnumber = "0100";
      let payload = {
        id: user.sub.replace("auth0|", ""),
        Epost: user.email,
        Kundnr: kundnr.data.maxnumber,
        Förnamn: "",
        Efternamn: "",
        Telefon: "",
        Adress: "",
        Postnummer: "",
        Postort: "",
        Kundtyp: kundtyp,
        Kontonummer: "",
        Clearingnr: "",
        Villkor: 1,
        Nyhetsbrev: 1,
        PricelistId
      };
      return await CallApi("post", "/kund", payload, state.accessToken);
    };

    if (!state.accessToken) {
      Initialize();
    }
  },[state.accessToken, getAccessTokenSilently, user, state]);

  return (
    <React.Fragment>
      {/* {this.state.error === true && (
      <h3>
        Tyvärr gick något fel, var god försök igen eller kontakta oss
        på kundtjanst@footly.se
      </h3>
    )} */}
      <div className="text-center">
        {/* <h4>Du är nu inloggad</h4> */}
        {state.kundnummer !== "" && (
          <div className="fade-in-bottom">
            <h4>
              Ditt inlämningsnummer är: <b>{state.kundnummer}</b>
            </h4>
            </div>
        )}
        {state.kundnummer !== "" && state.kundtyp === "" && (
          <div className="fade-in-bottom">
          <p>
            <b>
              Som ny kund måste du välja ifall du är privatperson eller
              företagskund under uppgifter.
            </b>
          </p>
          </div>
        )}
      </div>
      <StartIcons />
    </React.Fragment>
  );
};
export default LoggedIn;
