import React, { useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import CallApi from "../components/CallApi";

const handleSwishPayment = async (
  customerNumber,
  mobilnummer,
  personnummer,
  sum,
  customerId,
) => {
  const body = {
    payerPaymentReference: customerNumber.toString(),
    payeeAlias: mobilnummer,
    payeeSSN: personnummer,
    amount: sum.toString(),
    kundId: customerId,
  };

  return await CallApi("POST", "/payout", body);
};

const SwishPaymentDialog = ({
  isOpen,
  handleClose,
  header,
  abort,
  handleSubmit,
  customerNumber,
  customerId,
  sum,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, seterror] = useState("");

  const handleOk = async () => {
    let mobilnummer = document.getElementById("Mobilnummer").value;
    let personnummer = document.getElementById("Personnummer").value;
    personnummer = personnummer.replace(/-/g, "");
    mobilnummer = mobilnummer.replace(/[+-]/g, "");

    if (personnummer.length !== 12 || isNaN(personnummer)) {
      seterror("Personnumret ser inte ut att vara korrekt.");
      return;
    }
    const mobilnummerRegex = /^[1-9]{2}[^0]{1}[0-9]*$/;
    if (!mobilnummerRegex.test(mobilnummer)) {
      seterror("Mobilnumret ser inte ut att vara korrekt.");
      return;
    }

    setIsSubmitting(true);

    var response = await handleSwishPayment(
      customerNumber,
      mobilnummer,
      personnummer,
      Math.round(sum),
      customerId,
    );
    if (response.status === 201) {
      await handleSubmit(mobilnummer);
    } else {
      seterror(
        "Något gick fel. Kontrollera att mobilnummer och personnummer är i korrekt format. Fungerar det ändå inte så kontakta Footly.",
      );
    }
    setIsSubmitting(false);
  };

  return (
    <Modal
      show={isOpen}
      onHide={handleClose}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <img src="Swish.png" alt="Swish logo" className="swish-logo" />{" "}
          {header}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col>
              <p>
                Ange ditt personnummer och det mobilnumret du vill ha Swish
                till.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="Personnummer">
                <Form.Label>Personnummer (YYYYMMDDnnnn): </Form.Label>
                <Form.Control
                  type="text"
                  name="Personnummer"
                  placeholder="Personnummer (YYYYMMDDnnnn)"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="Mobilnummer">
                <Form.Label>Mobilnummer (467xxxxxxxx):</Form.Label>
                <Form.Control
                  type="text"
                  name="Mobilnummer"
                  placeholder="Mobilnummer (467xxxxxxxx)"
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col>
            <Form.Label>{error}</Form.Label>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          Avbryt
        </Button>
        {!abort && (
          <Button
            variant="primary"
            type="submit"
            onClick={handleOk}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Vänta..." : "Ok"}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default SwishPaymentDialog;
