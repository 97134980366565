import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

const ConfirmationDialog = ({ isOpen, handleClose, header, bodyText, abort, handleSubmit }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOk = async () => {
    setIsSubmitting(true);
    await handleSubmit(true);
    setIsSubmitting(false);
  };

  return (
    <Modal
      show={isOpen}
      onHide={handleClose}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {header}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p dangerouslySetInnerHTML={{ __html: bodyText }}>
      
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          Avbryt
        </Button>
        {!abort && (
          <Button
            variant="primary"
            type="submit"
            onClick={handleOk}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Vänta..." : "Ok"}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationDialog;
