import React, { Component } from "react";
import { Row, Col, Button, InputGroup, Form } from "react-bootstrap";
import { IsLoadingButton } from "./LoadingSpinner";
import { getCustomerByCustomerNumber } from "../Helpers/CustomerInfo";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import CallApi from "./CallApi";
import { CreateDropOff } from "../Helpers/Dropoffs";

class ManagePickup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      förnamn: "",
      efternamn: "",
      kundnr: "",
      kundId: "",
      dropOffs: null,
      pricelistId: "",
      showForm: false,
      status: "",
      isLoading: false,
      isLoading2: false,
      latestSyncDate: "",
      isSyncing: false,
      cost: 99,
      message: "",
      pickupDate: new Date()
    };
  }
  async componentDidMount() {
    const accessToken = await this.props.auth.getAccessTokenSilently();
    this.setState({ accessToken: accessToken });
  }

  onKeyPress = (e) => {
    if (e.charCode === 13) {
      this.searchkundnummer(e);
    }
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      [name]: value,
    });
  };

  searchkundnummer = (e) => {
    this.setState({ status: "", showForm: false, isLoading: true });
    e.preventDefault();
    this.getKundnr(this.state.kundnr);
  };
  createPickup = (e) => {
    this.setState({ isLoading2: true });
    e.preventDefault();
      let body = {KundId: this.state.kundId, noItems: 1, cost: 0, pickupCost: this.state.cost, handledDate: this.state.pickupDate, dropOffDate: this.state.pickupDate };
      this.updatePickupCost(body);
    // } else {
    //   this.dropOff(this.state.kundId, this.state.cost, this.state.accessToken, this.state.pickupDate);
    // }
  };
  dropOff = async (kundId, cost, token, pickupDate) => {
    var res = await CreateDropOff(kundId, token, cost, pickupDate, pickupDate );
    const message =
      res.dropOffId > 0 ? "Upphämtningskostnad registrerad" : "Något gick fel";
    this.setState({
      message: message,
      isLoading2: false,
    });
  };
  updatePickupCost = async (body) => {
    let res = await CallApi("Post", `/dropoff`, body, this.state.accessToken);
    const message =
      res.status === 201 ? "Upphämtningskostnad registrerad" : "Något gick fel";
    this.setState({
      message: message,
      isLoading2: false,
    });
  };

  getKundnr = async (kundnr) => {
    let response = await getCustomerByCustomerNumber(
      kundnr,
      this.state.accessToken
    );
    console.log("Response: ", response.data);
    if (response.status === 404) {
      this.setState({ status: "Inlämningsnummer saknas", isLoading: false });
    } else if (response.status !== 200) {
      this.setState({ status: response.statusText, isLoading: false });
    } else {
      var kund = response.data.kund;
      console.log(
        `Kund är:  ${kund.id} ${kund.Kundnr} ${kund.Förnamn} ${kund.Efternamn}, pricelistId: ${kund.PricelistId}`
      );
      let dropOffs = kund.DropOffs.filter((dropOff) => {
        return dropOff.handledDate === null;
      });
      this.setState({
        förnamn: kund.Förnamn,
        efternamn: kund.Efternamn,
        kundtyp: kund.Kundtyp,
        kundnr: kund.Kundnr,
        kundId: kund.id,
        email: kund.Epost,
        pricelistId: kund.PricelistId,
        dropOffs,
        showForm: true,
        isLoading: false,
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="call-to-action">
          <h2>Hantera upphämtning</h2>
          <Row>
            <Col>
              <div className="form-group">
                <input
                  id="kundnummerInput"
                  name="kundnr"
                  type="text"
                  value={this.state.kundnr}
                  onChange={this.handleChange}
                  placeholder="inlämningsnummer"
                  className="form-control"
                  onKeyPress={this.onKeyPress}
                />
              </div>
            </Col>
            <Col xs={2}>
              <Button
                className="btn-footly"
                id="searchButton"
                name="searchButton"
                onClick={this.searchkundnummer}
              >
                {this.state.isLoading && <IsLoadingButton />}
                Sök
              </Button>
            </Col>
            <Col xs={4}>
              <span className="status">{this.state.status}</span>
            </Col>
          </Row>
          {this.state.showForm ? (
            <>
              <Row>
                <Col>
                  <h4>
                    Namn på kunden: {this.state.förnamn} {this.state.efternamn},{" "}
                    {this.state.kundtyp}{" "}
                    <b>
                      {this.state.kundtyp === "Privatperson" ? "0% " : "25% "}
                      moms.
                    </b>
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <DatePicker
                    defaultValue={new Date()}
                    format="yyyy-MM-dd"
                    weekNumber={false}
                    id="datepicker"
                    onChange={this.handleChange}
                    name="pickupDate"

                  />
                </Col>
                <Col xs={3}>
                  <InputGroup>
                    <Form.Control
                      id="costInput"
                      name="cost"
                      type="text"
                      value={this.state.cost}
                      onChange={this.handleChange}
                      placeholder="Kostnad"
                      className="form-control"
                    />
                    <InputGroup.Text id="basic-addon2">kr</InputGroup.Text>{" "}
                  </InputGroup>
                </Col>
                <Col xs={3}>
                  <Button
                    className="btn-footly"
                    id="pickupButton"
                    name="pickupButton"
                    onClick={this.createPickup}
                  >
                    {this.state.isLoading2 && <IsLoadingButton />}
                    Ok
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  {" "}
                  <h4>{this.state.message}</h4>
                </Col>
              </Row>
            </>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

export default ManagePickup;
