import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";
import CallApi from "./CallApi";
import { CalculateCostPerItem } from "../Helpers/CalculatePriceList";

class FinishRegistration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      total: "",
      priceList: [],
      cost: null,
      message: "",
      error: "",
      isLoading: false,
      registerDropoffStatus: null,
      mailSent: false,
    };
  }

  // getProductsFromSello = async () => {
  //   this.setState({ isLoading: true, message: "" });
  //   console.log(`Det faktiska kundId:t är ${this.props.kundId}`);
  //   let kundnr = this.props.kundnr;
  //   let today = new Date();
  //   today.setDate(today.getDate() - 1);
  //   today = today.toJSON().slice(0, 10);

  //   if (typeof process.env.REACT_APP_ACCOUNT !== "undefined") {
  //     kundnr = process.env.REACT_APP_ACCOUNT;
  //     today = "2019-12-18"; //new Date().toJSON().slice(0, 10);
  //   }
  //   const res = await CallSello(
  //     "Get",
  //     `/products?filter[private_reference]=${kundnr}&filter[created_at]=gt ${today}`
  //   );
  //   this.setState({
  //     total: res.data.meta.total,
  //     isLoading: false,
  //     message: `
  //       Antal produkter skapade i Sello: ${res.data.meta.total}. Stämmer detta?`,
  //   });
  // };
  componentDidMount() {
    //this.getProductsFromSello();
    this.getPriceList();
  }
  handleChange = (e) => {
    this.setState({ total: e.target.value });
  };

  getPriceList = async () => {
    console.log(`Hämtar prislista för kund: ${this.props.kundId}`);
    const pricelistId = this.props.pricelistId;
    const res = await CallApi(
      "Get",
      `/pricelist/${pricelistId}`,
      "",
      this.props.accessToken,
    );
    this.setState({
      priceList: res.data.pricelist,
    });
  };
  calculateCost = async () => {
    const calculatedCost = await CalculateCostPerItem(
      this.state.total,
      this.state.priceList.CostPerItem,
    );

    this.setState({ cost: calculatedCost });
  };
  storeDropOff = async () => {
    if (this.state.total.trim() === "" || isNaN(this.state.total)) {
      this.setState({
        error: "Du måste ange ett giltigt antal produkter",
      });
      return;
    }

    const total = Number(this.state.total);
    this.setState({
      isLoading: true,
      message: "",
    });
    await this.calculateCost();
    await this.submitDropoff(this.props.dropOffId, total);
    //await this.sendMailToCustomer();
    this.setState({
      isLoading: false,
      error: "",
    });
  };
  submitDropoff = async (dropOffId, total) => {
    this.setState({ isLoading: true });
    const body = {
      handledDate: new Date(),
      KundId: this.props.kundId,
      cost: this.state.cost,
      noItems: total,
      dropOffId,
    };
    let operation = "Put";
    if (dropOffId === null) {
      body.dropOffDate = new Date();
      operation = "Post";
    }

    let res = await CallApi(
      operation,
      `/dropoff`,
      body,
      this.props.accessToken,
    );
    if (res.status !== 201) this.setState({ message: res.data.error });
    else {
      this.setState({
        message: "Inlämningstillfälle hanterat!",
        registerDropoffStatus: res.status,
      });
    }
  };
  sendMailToCustomer = async () => {
    const body = {
      recipient: this.props.email,
      total: this.state.total,
    };
    await CallApi("Post", `/dropoffmail`, body, this.props.accessToken);
    this.setState({ message: "Mail skickat till inlämnare", mailSent: true });
  };

  render() {
    return (
      <React.Fragment>
        <div className="sello-result-container ">
          <Row>
            <Col>
              <h4>
                Status: <b>{this.state.message}</b>
              </h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>{this.state.error}</p>
            </Col>
          </Row>
          {this.state.message === "" ? (
            <Row>
              <Col>
                <div className="form-group">
                  <input
                    onChange={this.handleChange}
                    className="form-control"
                    placeholder="Antal artiklar"
                    value={this.state.total}
                  />
                </div>
              </Col>

              <Col>
                <Button
                  className="btn-block"
                  id="okButton"
                  name="okButton"
                  type="button"
                  onClick={this.storeDropOff}
                >
                  Ok
                </Button>
              </Col>
            </Row>
          ) : null}
          {this.state.registerDropoffStatus === 201 ? (
            <Row>
              <Col>
                <h4>Maila inlämnare: alla saker klara </h4>
              </Col>
              <Col>
                <Button
                  className="btn-block"
                  id="sendMailButton"
                  name="sendMailButton"
                  type="button"
                  onClick={this.sendMailToCustomer}
                  disabled={this.state.mailSent === true}
                >
                  Skicka mail
                </Button>
              </Col>
            </Row>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}
export default FinishRegistration;
