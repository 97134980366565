import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { IsLoadingButton } from "./LoadingSpinner";
import { getCustomerByCustomerNumber } from "../Helpers/CustomerInfo";
import MyAccount from "./MyAccount";

class MyAccountAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      förnamn: "",
      efternamn: "",
      kundnr: "",
      kundId: "",
      isLoading: false,
      status: "",
    };
  }
  onKeyPress = (e) => {
    if (e.charCode === 13) {
      this.searchkundnummer(e); 
    }
  };
  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
    });
  };
  searchkundnummer = (e) => {
    this.setState({ status: "", showForm: false, isLoading: true });
    e.preventDefault();
    this.getKundnr(this.state.kundnr);
  };
  getKundnr = async (kundnr) => {
    const token = await this.props.auth.getAccessTokenSilently();
    let response = await getCustomerByCustomerNumber(kundnr, token);
    console.log("Response: ", response.data);
    if (response.status === 404) {
      this.setState({ status: "Inlämningsnummer saknas", isLoading: false });
    } else if (response.status !== 200) {
      this.setState({ status: response.statusText, isLoading: false });
    } else {
      var kund = response.data.kund;
      console.log(
        `Kund är:  ${kund.id} ${kund.Kundnr} ${kund.Förnamn} ${kund.Efternamn}, pricelistId: ${kund.PricelistId}`
      );
      this.setState({
        förnamn: kund.Förnamn,
        efternamn: kund.Efternamn,
        kundtyp: kund.Kundtyp,
        kundnr: kund.Kundnr,
        kundId: kund.id,
        email: kund.Epost,
        showForm: true,
        isLoading: false,
      });
    }
  };

  render() {
    return (
      <>
        <div className="call-to-action">
          <Row>
            <Col xs={6}>
              <div className="form-group">
                <input
                  id="kundnummerInput"
                  name="kundnr"
                  type="text"
                  value={this.state.kundnr}
                  onChange={this.handleChange}
                  placeholder="inlämningsnummer"
                  className="form-control"
                  onKeyPress={this.onKeyPress}
                />
              </div>
            </Col>
            <Col xs={6}>
              {" "}
              <Button
                className="btn-footly"
                id="searchButton"
                name="searchButton"
                onClick={this.searchkundnummer}
              >
                {this.state.isLoading && <IsLoadingButton />}
                Sök
              </Button>
            </Col>
          
          </Row>
          <Row>
            <Col>
              <span className="status">{this.state.status}</span>
            </Col>
          </Row>
          <Row>
            <Col>
              {this.state.förnamn} {this.state.efternamn}
              <br /> {this.state.email}
            </Col>
          </Row>
          {this.state.showForm && (
            <MyAccount
              auth={this.props.auth}
              customerId={this.state.kundId}
              customerEmail={this.state.email}
            />
          )}
        </div>
      </>
    );
  }
}
export default MyAccountAdmin;
