const axios = require("axios");
export default async function CallSello(method, endpoint, body) {
  try {
    let response = null;
    if (body !== "") {
      response = await axios(`${process.env.REACT_APP_SELLO_API}${endpoint}`, {
        method,
        data: body,
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          authorization: `${process.env.REACT_APP_SELLO_TOKEN}`
        }
      });
    } else {
      response = await axios(`${process.env.REACT_APP_SELLO_API}${endpoint}`, {
        method,
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          authorization: `${process.env.REACT_APP_SELLO_TOKEN}`
        }
      });
    }
    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
}
