import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";
import CallApi from "./CallApi";
import CallSello from "./CallSello";
import { IsLoading } from "./LoadingSpinner";
import {
  CalculateEarningPerItem,
  CalculateCostPerItem,
  CalculateBalance
} from "../Helpers/CalculatePriceList";

class FinishReturns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: "",
      priceList: [],
      cost: null,
      message: "",
      isLoading: false
    };
  }

  getProductsFromSello = async () => {
    this.setState({ isLoading: true, message: "" });

    let kundnr = this.props.kundnr;
    let today = new Date().toJSON().slice(0, 10);
    if (typeof process.env.REACT_APP_ACCOUNT !== "undefined") {
      kundnr = process.env.REACT_APP_ACCOUNT;
      today = "2019-12-18"; //new Date().toJSON().slice(0, 10);
    }
    await CallSello(
      "Get",
      `/products?filter[private_reference]=${kundnr}&filter[created_at]=gt ${today}`
    );
  };
  async componentDidMount() {
    // this.getProductsFromSello();
    await this.getPriceList();
    await this.calculatePreviousEarnings();
  }
  getPriceList = async () => {
    const pricelistId = this.props.pricelistId;
    const res = await CallApi(
      "Get",
      `/pricelist/${pricelistId}`,
      "",
      this.props.accessToken
    );
    this.setState({
      priceList: res.data.pricelist
    });
  };
  calculatePreviousEarnings = async () => {
    let products = [];
    const fixedEarningPerItem = this.state.priceList.CostPerSold > 0;
    let sumEarningsWithCostDeducted = 0;

    for (const item of this.props.items) {
      let noItems = item.amount.replace(" st,", "");
      let price = item.price.replace(" kr", "");
      let description = item.text;
      let sum = noItems * price;
      let [earning, cost] = await Promise.all([
        CalculateEarningPerItem(this.state.priceList, fixedEarningPerItem, sum),
        CalculateCostPerItem(noItems, this.state.priceList.CostPerItem)
      ]);
      let earningsWithCostDeducted = await CalculateBalance(earning, cost);
      products.push({
        noItems,
        description,
        price,
        earning: earningsWithCostDeducted,
        KundId: this.props.kundId,
        paid: false
      });
      sumEarningsWithCostDeducted += earningsWithCostDeducted;
    }
    console.log("products: ", products);
    this.setState({
      sumEarningsWithCostDeducted,
      message: `       
    Belopp att få återbetalat: ${sumEarningsWithCostDeducted} kr. Stämmer detta?`,
      products
    });
  };

  submitReturn = async () => {
    this.setState({
      isLoading: true,
      message: ""
    });

    let res = await CallApi(
      "Post",
      `/returns`,
      this.state.products,
      this.props.accessToken
    );
    let message;
    if (res.status === 201) {
      message = "Retur registrerad";
    } else {
      message = "Något gick fel: " + res.statustext;
    }
    this.setState({
      message,
      isLoading: false
    });
  };

  sendMailToCustomer = async () => {
    const body = {
      recipient: this.props.email,
      total: this.state.total,
      scrappedItems: this.props.items.length
    };
    await CallApi("Post", `/dropoffmail`, body, this.props.accessToken);
  };

  render() {
    return (
      <React.Fragment>
        <div className="sello-result-container ">
          <Row>
            <Col>
              {this.state.isLoading && <IsLoading />}
              <h4>{this.state.message}</h4>
            </Col>

            <Col>
              <Button
                className="btn-block"
                id="okButton"
                name="okButton"
                type="button"
                size="lg"
                onClick={this.submitReturn}
              >
                Ok
              </Button>
            </Col>
            <Col>
              <Button
                className="btn-block"
                id="hamtaButton"
                name="hamtaButton"
                type="button"
                size="lg"
                onClick={this.calculatePreviousEarnings}
              >
                Beräkna igen
              </Button>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}
export default FinishReturns;
