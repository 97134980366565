import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { TrashItems } from "../../TrashItems";
import FinishRegistration from "../../FinishRegistration";
import CallApi from "../../CallApi";
import ApparelButtons from "./ApparelButtons";
import ReasonButtons from "./ReasonButtons";
import AmountButtons from "./AmountButtons";
import AddMeasureButtons from "./AddMeasureButtons";

function RegistrationForm(props) {
  const [scrappedList, setScrappedList] = useState([]);
  const [dropOffId, setDropOffId] = useState(null);
  const [showFinishResult, setShowFinishResult] = useState(false);
  const [isLoading] = useState(false);
  const [dropOffDate, setDropOffDate] = useState(null);
  const [errormessage, setErrormessage] = useState("");
  const [apparel, setApparel] = useState("");
  const [reason, setReason] = useState("");
  const [amount, setAmount] = useState("");
  const [measure, setMeasure] = useState("");
  const [state] = useState({});

  const fetchDropOffs = async (id, token) => {
    try {
      const response = await CallApi("GET", `/dropoff/${id}`, null, token);
      if (response.status === 200) {
        setScrappedList(response.data.dropOff.Scrappeds);
      }
    } catch (error) {
      console.error("Failed to fetch drop-offs:", error);
      // Handle the error state appropriately
    }
  };
  const createDropOff = async (kundId, token) => {
    try {
      const dropOffDate = new Date().toUTCString();
      const res = await CallApi(
        "POST",
        "/dropoff",
        { dropOffDate, kundId },
        token,
      );
      if (res.status === 201) {
        return res.data.message;
      }
    } catch (error) {
      console.error("Failed to create a new drop-off:", error);
      // Handle the error state appropriately
    }
  };

  useEffect(() => {
    if (props.dropOffs.length) {
      const { id, dropOffDate } = props.dropOffs[0];
      if (id !== null) fetchDropOffs(id, props.accessToken);
      setDropOffId(id);
      setDropOffDate(dropOffDate);
    }
  }, [props.dropOffs, props.accessToken, props.kundId, setDropOffDate]);

  const handleApparelChanged = (e) => {
    setApparel(e);
  };
  const handleMeasureChanged = (e) => {
    setMeasure(e);
  };

  const handleReasonChanged = (e) => {
    setReason(e);
  };
  const handleAmountChanged = (e) => {
    setAmount(e);
  };

  const addItem = async (e) => {
    e.preventDefault();
    if (amount && reason && measure) {
      const apparelEntered = apparel.trim() === "" ? "" : apparel + ",";
      const newItem = {
        Antal: `${amount}`,
        Orsak: `${apparelEntered} ${reason}, ${measure}`,
        id: Date.now(),
      };
      const updatedScrap = [
        ...scrappedList,
        {
          Antal: newItem.Antal.replace(" st,", ""),
          Orsak: newItem.Orsak,
          id: newItem.id,
        },
      ];
      let id = dropOffId;
      if (dropOffId === null) {
        id = await createDropOff(props.kundId, props.accessToken);
      }

      saveItem(updatedScrap, id);
      setScrappedList(updatedScrap);
      resetStates();
      setDropOffId(id);

      const apparelControl = document.getElementById("apparel");
      apparelControl.focus();
    }
  };
  const resetStates = () => {
    const statesToReset = [setApparel, setAmount, setReason, setMeasure];
    statesToReset.forEach((state) => state(""));
  };
  const saveItem = async (scrappedList, dropOffId) => {
    const body = {
      KundId: props.kundId,
      Scrapped: scrappedList,
      dropOffId,
    };

    await CallApi("Delete", `/dropoff/${dropOffId}`, body, props.accessToken);
    let res = await CallApi("Put", `/dropoff`, body, props.accessToken);
    if (res.status === 500) setErrormessage(res.data.message);
  };

  const deleteItem = async (id) => {
    var filteredItems = scrappedList.filter(function (item) {
      return item.id !== id;
    });
    await saveItem(filteredItems, dropOffId);
    setScrappedList(filteredItems);
  };
  const finishResult = () => {
    setShowFinishResult(true);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      addItem();
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col>
          <p>
            {props.förnamn} {props.efternamn}, {props.email}, {props.kundtyp}{" "}
            {/* <b>{props.kundtyp === "Privatperson" ? "0% " : "25% "}moms.</b> */}
            {/* {new Date(this.state.dropOffDate).toISOString().split("T")[0] +
                " "} */}
          </p>
          <hr />
        </Col>
      </Row>
      <Form>
        <ApparelButtons
          apparel={apparel}
          handleApparelChanged={handleApparelChanged}
          handleKeyPress={handleKeyPress}
        />
        <hr />
        <ReasonButtons
          reason={reason}
          handleReasonChanged={handleReasonChanged}
          handleKeyPress={handleKeyPress}
        />
        <hr />
        <AmountButtons
          amount={amount}
          handleAmountChanged={handleAmountChanged}
          handleKeyPress={handleKeyPress}
        />
        <hr />
        <AddMeasureButtons
          measure={measure}
          handleMeasureChanged={handleMeasureChanged}
          handleKeyPress={handleKeyPress}
        />
        <Row>
          <Col>
            <div className="form-group">
              <Button
                className="btn-block btn-footly"
                id="addButton"
                name="addButton"
                type="submit"
                size="sm"
                onClick={addItem}
              >
                Lägg till
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <TrashItems items={scrappedList} deleteItem={deleteItem} />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="form-group">
              <Button
                className="btn-block btn-footly-blue"
                id="avslutaButton"
                name="avslutaButton"
                type="button"
                size="sm"
                onClick={!isLoading ? finishResult : null}
                disabled={showFinishResult}
              >
                {isLoading ? "Arbetar..." : " Avsluta inlämning"}
              </Button>
              <h4>{errormessage}</h4>
            </div>
            {showFinishResult ? (
              <FinishRegistration
                kundnr={props.kundnr}
                dropOffId={dropOffId}
                pricelistId={props.pricelistId}
                accessToken={props.accessToken}
                items={state.items}
                email={props.email}
                kundId={props.kundId}
              />
            ) : null}
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
}

export default RegistrationForm;
