import CallApi from "../components/CallApi";

const SendPaymentRequestedMail = async (
  sum,
  accountnumber,
  mail,
  accessToken,
  sender,
  customerType,
  customerNumber,
  name,
  dropOffCost,
  paymentDate,
  customerPaymentDate,
  soldInSello,
  doljForsaljningspris,
  mobilnummer,
  purchases,
) => {
  console.log(process.env.REACT_APP_MAIL);
  if (typeof process.env.REACT_APP_MAIL !== "undefined") {
    mail = process.env.REACT_APP_MAIL;
  }
  const vat = sum * 0.25;
  const props = {
    total: sum,
    accountnumber: accountnumber,
    recipient: mail,
    customertype: customerType,
    customernumber: customerNumber,
    name,
    vat,
    dropOffCost,
    paymentDate,
    customerPaymentDate,
    soldInSello,
    doljForsaljningspris,
    mobilnummer,
    purchases,
  };
  switch (sender) {
    case "p":
      try {
        const res = await CallApi(
          "Post",
          `/paymentrequestedmail`,
          props,
          accessToken,
        );
        console.log(res);
        return res;
      } catch (error) {
        console.log("Error> ", error);
        return error;
      }

    case "v":
      try {
        const res = await CallApi(
          "Post",
          `/paymentwellfarerequestedmail`,
          props,
          accessToken,
        );
        console.log(res);
        return res;
      } catch (error) {
        console.log("Error: ", error);
        return error;
      }
    case "f":
      try {
        const res = await CallApi(
          "Post",
          `/paymentfootlyrequestedmail`,
          props,
          accessToken,
        );
        return res;
      } catch (error) {
        console.log("Error:", error);
        return error;
      }
    case "s":
      try {
        props.paymentDate = new Date();
        const res = await CallApi(
          "Post",
          `/paymentswishrequestedmail`,
          props,
          accessToken,
        );
        return res;
      } catch (error) {
        console.log("Error: ", error);
        return error;
      }

    default:
      break;
  }
};

const GeneratePayment = async (
  sum,
  accountnumber,
  mail,
  accessToken,
  sender,
  customerType,
  customerNumber,
  name,
  customerId,
  dropOffCost,
  paymentDate,
  customerPaymentDate,
  soldInSello,
  doljForsaljningspris,
  mobilnummer,
  purchases,
) => {
  try {
    //run the calls to CallApi in parallel but make sure every call is finished before returning
    const call1 = CallApi(
      "Post",
      `/sold/${customerNumber}`,
      '{"paid" : true}',
      accessToken,
    );
    const call2 = CallApi(
      "Put",
      `/updatedropoffstopaid`,
      `{"customerId" : "${customerId}"}`,
      accessToken,
    );
    const call3 = CallApi(
      "Post",
      `/payments`,
      `{"sum" : "${sum}","paymentDate" : "${new Date()}","KundId" : "${customerId}"}`,
      accessToken,
    );
    const call4 = CallApi(
      "Post",
      `/purchase/${customerNumber}`,
      '{"paid" : true}',
      accessToken,
    );

    await Promise.all([call1, call2, call3, call4]);

    return await SendPaymentRequestedMail(
      Math.round(sum),
      accountnumber,
      mail,
      accessToken,
      sender,
      customerType,
      customerNumber,
      name,
      dropOffCost,
      paymentDate,
      customerPaymentDate,
      soldInSello,
      doljForsaljningspris,
      mobilnummer,
      purchases,
    );
  } catch (error) {
    console.log("Error ", error);
    return error;
  }
};

export { GeneratePayment, SendPaymentRequestedMail };
