import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import CallApi from "./CallApi";
import { CommandCell } from "../Helpers/CommandCell";
import { DropDown } from "../Helpers/DropDown";

export default class ManagePricelist extends Component {
  editField = "inEdit";
  constructor(props) {
    super(props);
    this.state = {
      pricelists: [],
      sort: [{ field: "Name" }],
      accessToken: props.auth.getAccessToken,
      editItem: null,
    };
  }

  componentDidMount() {
    this.getPriceLists();
  }
  rowClick = (event) => {
    this.setState({
      editID: event.dataItem.id,
      editItem: event.dataItem,
    });
  };

  itemChange = (event) => {
    const inEditID = event.dataItem.id;
    const pricelists = this.state.pricelists.map((item) =>
      item.id === inEditID ? { ...item, [event.field]: event.value } : item,
    );
    this.setState({ pricelists });
  };

  closeEdit = (event) => {
    if (event.target === event.currentTarget) {
      this.setState({ editID: null });
    }
  };
  addRecord = () => {
    const { pricelists } = this.state;
    const newRecord = { VMB: false };

    this.setState({
      pricelists: [newRecord, ...pricelists],
      // editID: newRecord.id
    });
  };

  remove = async (dataItem) => {
    await await CallApi(
      "Delete",
      `/pricelist/${dataItem.id}`,
      "",
      this.state.accessToken,
    );
    this.setState({
      pricelists: this.state.pricelists.filter((p) => p.id !== dataItem.id),
    });
  };

  add = async (dataItem) => {
    dataItem.inEdit = undefined;
    var res = await CallApi(
      "Post",
      "/pricelist",
      dataItem,
      this.state.accessToken,
    );
    var id = res.data.id;
    var pl = this.state.pricelists;
    pl[0].id = id;
    this.setState({
      pricelists: [...pl],
    });
    // this.closeEdit();
  };
  update = async (dataItem) => {
    const pricelists = [...this.state.pricelists];
    const updatedItem = { ...dataItem, inEdit: undefined };

    this.updateItem(pricelists, updatedItem);
    await CallApi(
      "Put",
      `/pricelist/${updatedItem.id}`,
      updatedItem,
      this.state.accessToken,
    );

    this.setState({ pricelists, editID: null });
  };

  updateItem = (data, item) => {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id),
    );
    if (index >= 0) {
      data[index] = { ...item };
    }
  };
  cancel = (dataItem) => {
    const originalItem = this.state.editItem;
    let pricelists = this.state.pricelists;
    if (originalItem !== null) {
      pricelists = this.state.pricelists.map((item) =>
        item.id === originalItem.id ? originalItem : item,
      );
    } else {
      pricelists = this.state.pricelists.filter((p) => p.id !== dataItem.id);
    }

    this.setState({ pricelists, editID: null });
  };

  getPriceLists = async () => {
    const response = await CallApi(
      "Get",
      "/pricelist",
      "",
      this.state.accessToken,
    );
    this.CommandCell = CommandCell({
      edit: this.enterEdit,
      remove: this.remove,

      add: this.add,
      discard: this.discard,

      update: this.update,
      cancel: this.cancel,

      editField: this.editField,
    });
    if (response.data === undefined) {
      this.setState({ message: response.message });
      return;
    }
    const pricelists = await response.data.Pricelists;
    this.setState({ pricelists });
  };
  render() {
    ////cell={checkboxColumn}
    return (
      <div className="call-to-action">
        <h2>Prislista</h2>
        <h4>{this.state.message}</h4>
        <Grid
          data={orderBy(
            this.state.pricelists.map((item) => ({
              ...item,
              inEdit: item.id === this.state.editID,
            })),
            this.state.sort,
          )}
          editField={this.editField}
          onRowClick={this.rowClick}
          onItemChange={this.itemChange}
          sortable
          sort={this.state.sort}
          onSortChange={(e) => {
            if (this.state.editItem === null) {
              this.setState({
                sort: e.sort,
              });
            }
          }}
        >
          <GridToolbar>
            <div onClick={this.closeEdit}>
              <Button title="Lägg till" onClick={this.addRecord}>
                <span className="k-icon k-i-plus" />
                Lägg till
              </Button>
            </div>
          </GridToolbar>
          <GridColumn
            field="Name"
            title="Namn"
            editor="text"
            sortable
            width="180px"
          />

          <GridColumn
            //todo: I want in the editor to be able to choose between "Privat" and "Företag"
            field="VMB"
            title="Kundtyp"
            editor="text"
            width="110px"
            cell={DropDown}
            // cell={(props) => (
            //   <td>{props.dataItem[props.field] ? "Privat" : "Företag"}</td>
            // )}
          />

          <GridColumn
            field="ProvisionAmount1"
            title="Nivå 1 :-"
            editor="text"
            sortable={true}
            width="90px"
          />
          <GridColumn
            field="ProvisionPercent1"
            title="Nivå 1 %"
            editor="text"
            sortable={true}
            width="90px"
          />
          <GridColumn
            field="ProvisionAmount2"
            title="Nivå 2 :-"
            editor="text"
            sortable={true}
            width="90px"
          />
          <GridColumn
            field="ProvisionPercent2"
            title="Nivå 2 %"
            editor="text"
            sortable={true}
            width="90px"
          />
          <GridColumn
            field="ProvisionPercent3"
            title="Resten %"
            editor="text"
            sortable={true}
            width="90px"
          />

          {/* <GridColumn
            field="ProvisionLess"
            title="Prov. under"
            editor="numeric"
            width="120px"
          />
          <GridColumn
            field="ProvisionGreater"
            editor="numeric"
            title="Prov. över"
            width="120px"
          /> */}
          <GridColumn
            field="CostPerItem"
            editor="numeric"
            title="Per vara"
            width="90px"
          />
          <GridColumn
            field="CostPerSold"
            editor="numeric"
            title="Per såld"
            width="90px"
          />
          <GridColumn cell={this.CommandCell} />
        </Grid>
        <Row>
          <Col>
            {/* <Table responsive size="sm">
              <thead>
                <tr>
                  <th></th>
                  <th>Namn</th>
                  <th>VMB</th>
                  <th>Summa</th>
                  <th>Prov. under</th>
                  <th>Prov. över</th>
                  <th>kost./vara</th>
                  <th>kost./såld</th>
                </tr>
              </thead>
              <tbody>
                {this.state.pricelists.map(function(item) {
                  return (
                    <tr key={item.id}>
                      <td>
                        <Link
                          to={{
                            pathname: "/profile",
                            state: { id: item.id }
                          }}
                        >
                          <i
                            className="material-icons"
                            //   style="font-size: 60px;color: #6c935c;height: 60px;"
                          >
                            edit
                          </i>
                        </Link>
                      </td>
                      <td>{item.Name}</td>
                      <td>
                        <input type="checkbox" checked={item.VMB} />
                      </td>
                      <td>{item.ProvisionAmount}</td>
                      <td>{item.ProvisionLess}</td>
                      <td>{item.ProvisionGreater}</td>
                      <td>{item.CostPerItem}</td>
                      <td>{item.CostPerSold}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table> */}
          </Col>
        </Row>
      </div>
    );
  }
}
