import React, { useRef, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";

const AddMeasureButtons = (props) => {
  const _measure = useRef(props.measure);

  useEffect(() => {
    _measure.current.value = props.measure;
  }, [props.measure]);
  const addMeasure = (e) => {
    e.preventDefault();
    let value = e.target.value;
    _measure.current.value = value;
    props.handleMeasureChanged(_measure.current.value);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    props.handleMeasureChanged(value);
  };
  return (
    <>
      <Row className="align-items-baseline">
        <Col sm="2">
          <h4>Åtgärd:</h4>
        </Col>
        <Col sm="10">
          <div className="form-group">
            <input
              ref={_measure}
              className="form-control"
              placeholder="Åtgärd"
              onChange={handleChange}
              onKeyPress={props.onKeyPress}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            id="skanktButton"
            name="skanktButton"
            className="btn-info btn-block"
            size="sm"
            value="skänkt till välgörenhet"
            onClick={addMeasure}
          >
            Skänkt
          </Button>
        </Col>
        <Col>
          <Button
            id="kallsorterasButton"
            name="kallsorterasButton"
            className="btn-info btn-block"
            size="sm"
            value="källsorteras"
            onClick={addMeasure}
          >
            Källsorteras
          </Button>
        </Col>
        <Col>
          <Button
            id="tillfallerFootlyButton"
            name="tillfallerFootlyButton"
            className="btn-info btn-block"
            size="sm"
            value="tillfaller Footly"
            onClick={addMeasure}
          >
            Tillfaller Footly
          </Button>
        </Col>
      </Row>
      <hr />
    </>
  );
};
export default AddMeasureButtons;
