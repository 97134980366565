// @ts-nocheck
import React from "react";
import logo from "../footly-16K.png";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const Header = (props) => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const logoutWithRedirect = (e) => {
    e.preventDefault();
    logout({
      returnTo: process.env.REACT_APP_AUTH0_CALLBACK_URL,
    });
  };
  const { roles } = props;
  var admin = roles.includes("Admin");
  return (
    <div className="app-header">
      <Container>
        <Navbar bg="light" expand="lg">
          <Navbar.Brand href="/">
            <img src={logo} className="app-logo" alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <>
                <li>
                  <Link to="/myaccount" className="nav-link">
                    Saldo
                  </Link>
                </li>
                <li>
                  <Link to="/pickup" className="nav-link">
                    Lämna in
                  </Link>
                </li>
                {/* <li>
                  <Link to="/registerdropoff" className="nav-link">
                    Registrera inlämning
                  </Link>
                </li> */}
              </>

              <li>
                <Link to="/profile" className="nav-link">
                  Uppgifter
                </Link>
              </li>

              {isAuthenticated && admin && (
                <NavDropdown title="Admin" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/dropOff">
                    Hantera inlämning
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/myaccountadmin">
                    Kundens saldo
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/customers">Kunder</NavDropdown.Item>
                  {/* <NavDropdown.Item href="/returns">
                        Hantera returer
                      </NavDropdown.Item> */}
                  <NavDropdown.Item href="/pricelist">
                    Hantera prislista
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item href="/search">Sök</NavDropdown.Item> */}
                  <NavDropdown.Item href="/ManagePickup">
                    Hantera upphämtning
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/sellosync">
                    Sello synk
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/purchaseOrder">
                    Inköpsorder
                  </NavDropdown.Item>
                </NavDropdown>
              )}
              <li>
                <a
                  href="/"
                  className="nav-link hand"
                  onClick={
                    isAuthenticated ? logoutWithRedirect : loginWithRedirect
                  }
                >
                  {isAuthenticated ? "Logga ut" : "Logga in"}
                </a>
              </li>
            </Nav>
            {/* <Form inline>
            <FormControl type="text" placeholder="Search" className="mr-sm-2" />
            <Button variant="outline-success">Search</Button>
          </Form> */}
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </div>
  );
};

export default Header;
