import React, { useRef, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";

const ApparelButtons = (props) => {
  const _apparel = useRef(props.apparel);

  useEffect(() => {
    _apparel.current.value = props.apparel;
  }, [props.apparel]);

  const addApparel = (e) => {
    e.preventDefault();
    let value = e.target.value;
    if (_apparel.current.value !== "") {
      _apparel.current.value = _apparel.current.value + ", " + value;
    } else {
      _apparel.current.value = value;
    }
    props.handleApparelChanged(_apparel.current.value);
  };
  const handleChange = (event) => {
    const { value } = event.target;
    props.handleApparelChanged(value);
  };

  return (
    <>
      <Row className="align-items-baseline">
        <Col sm="2">
          <h4>Vara:</h4>
        </Col>
        <Col sm="10">
          <input
            ref={_apparel}
            className="form-control"
            placeholder="Vara"
            name="apparel"
            id="apparel"
            type="text"
            onChange={handleChange}
            onKeyPress={props.onKeyPress}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            id="damkladerButton"
            name="damkladerButton"
            className="btn-info btn-block"
            value="damkläder"
            size="sm"
            onClick={addApparel}
          >
            Damkläder
          </Button>
        </Col>
        <Col>
          <Button
            id="herrkladerButton"
            name="HerrkladerButton"
            className="btn-info btn-block"
            size="sm"
            value="herrkläder"
            onClick={addApparel}
          >
            Herrkläder
          </Button>
        </Col>
        <Col>
          <Button
            id="barnkladerButton"
            name="barnkladerButton"
            className="btn-info btn-block"
            size="sm"
            value="barnkläder"
            onClick={addApparel}
          >
            Barnkläder
          </Button>
        </Col>
        <Col>
          <Button
            id="skorButton"
            name="skorButton"
            className="btn-info btn-block"
            size="sm"
            value="skor"
            onClick={addApparel}
          >
            Skor
          </Button>
        </Col>
        <Col>
          <Button
            id="sheinButton"
            name="sheinButton"
            className="btn-info btn-block"
            size="sm"
            value="Shein"
            onClick={addApparel}
          >
            Shein
          </Button>
        </Col>
      </Row>
    </>
  );
};
export default ApparelButtons;
