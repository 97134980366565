import React, { useState, useEffect } from "react";
import { getCustomer } from "../Helpers/CustomerInfo";
import { Button, Row, Col } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { LoadingSpinner } from "./LoadingSpinner";
import CallApi from "./CallApi";

function Pickup(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [daysUntilShippingLabel, setDaysUntilShippingLabel] = useState("");
  const [pickupMessage, setPickupMessage] = useState("");
  const [shippingLabelMessage, setShippingLabelMessage] = useState("");
  const [kund, setKund] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [shippingLabelDisabled, setShippingLabelDisabled] = useState(false);
  const [shippingLabel, setShippingLabel] = useState("0");
  const [shippingLabelNotSelected, setShippingLabelNotSelected] = useState("");
  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    async function fetchData() {
      const token = await props.auth.getAccessTokenSilently();
      setAccessToken(token);
      const kundres = await getCustomer(props.auth.user, token);
      if (kundres.status === 200) {
        if (kundres.data.kund !== null) {
          let shippingLabelDisabled = false;
          const shippingLabelDate = kundres.data.kund.ShippingLabelOrdered;
          const THIRTEEN_DAYS = 13 * 24 * 60 * 60 * 1000;
          if (
            shippingLabelDate !== null &&
            new Date(shippingLabelDate).getTime() + THIRTEEN_DAYS > new Date().getTime()
          ) {
            let diff = new Date(shippingLabelDate).getTime() + THIRTEEN_DAYS - new Date().getTime()
            let days = Math.round(diff / (24 * 60 * 60 * 1000));
            setDaysUntilShippingLabel(`Dagar kvar till nya etiketter: ${days}`);
            shippingLabelDisabled = true;
          }

          setKund(kundres.data.kund);
          setDisabled(false);
          setIsLoading(false);
          setShippingLabelDisabled(shippingLabelDisabled);
        } else {
          setMessage("Något gick fel, försök igen eller kontakta Footly");
          setIsLoading(false);
        }
      }
    }
    fetchData();
  }, [props.auth]);

  const registerPickup = async (kund) => {
    const name = `${kund.Förnamn} ${kund.Efternamn}`;
    const address = `${kund.Adress}, ${kund.Postnummer}, ${kund.Postort}`;
    const body = {
      name,
      address,
      email: kund.Epost,
      phone: kund.Telefon,
      customerno: kund.Kundnr,
    };
    const status = await sendMail(body, "/pickuprequested");
    if (status === 200) {
      setPickupMessage("Upphämtning bokad. Vi kontaktar dig snarast för att boka tid.");
      setDisabled(true);
    }
  };
  const sendMail = async (body, endpoint) => {
    const res = await CallApi(
      "Post",
      endpoint,
      body,
      accessToken
    );
    if (res.status === 200) {
      return res.status
    } else {
      setMessage("Något gick fel, försök igen eller kontakta Footly");
      setIsLoading(false);
    }
  };

  function handleRadioChange(e) {
    e.persist();
    setShippingLabel(e.target.value);
  };

  const handleShippingLabel = async (kund) => {
    if (shippingLabel === "0") {
      setShippingLabelMessage("Du måste välja ett antal fraktetiketter");
    }
    else {
      setShippingLabelNotSelected("");
      const name = `${kund.Förnamn} ${kund.Efternamn}`;
      const address = `${kund.Adress}, ${kund.Postnummer}, ${kund.Postort}`;
      const body = {
        name,
        address,
        email: kund.Epost,
        phone: kund.Telefon,
        customerno: kund.Kundnr,
        shippingLabel: shippingLabel
      };
      const status = await sendMail(body, "/shippinglabelrequested");
      if (status === 200) {
        setShippingLabelMessage("Fraktetiketter beställt. Vi kontaktar dig snarast!");
        setShippingLabelDisabled(true);
        await CallApi("Put", `/kund/${kund.id}`, `{"ShippingLabelOrdered" :  "${new Date().toISOString()}"}`, accessToken);
      }
    }
  };
  return (
    <div className="call-to-action">
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <>
          <h2>Lämna in</h2>
          <hr />
          <h2>Boka hämtning</h2>
          <p>Vi hämtar inom Jönköpings kommun.<br />
            Kostnadsfritt &  obegränsat, du får lämna in hur mycket du vill!</p>
          {!kund.Adress || !kund.Postort ? (
            <p>
              Du måste fylla i din adress under{" "}
              <a href="/profile">uppgifter</a> för att kunna boka hämtning
            </p>
          ) : (
            <>
              <Row>
                <Col xs={5} lg={3}>
                  <Button
                    className="btn-footly"
                    id="registerPickupButton"
                    name="registerPickupButton"
                    onClick={() => registerPickup(kund)}
                    disabled={disabled}
                  >
                    {" "}
                    Boka upphämtning
                  </Button>
                </Col>
                <Col xs={4}>
                  <span>{pickupMessage} </span>
                </Col>
                <Col xs={3} />
              </Row>
            </>
          )}
          <hr />
          <h2>Lämna in på plats</h2>
          <p>Kostnadsfritt &  obegränsat, du får lämna in hur mycket du vill!</p>
          <p>Footly<br />Sjöhultsvägen 13<br />
            562 41 Taberg</p>
          <Button
            className="btn-footly"
            id="openingHours"
            name="openingHoursButton"
            href="https://www.footly.se/clickandcollect/"
            target="_blank"
          >
            {" "}
            Öppettider för inlämning
          </Button>
          {/* <h3><a href="https://www.footly.se/clickandcollect/" target="_blank">Öppettider för inlämning.</a></h3> */}
          <hr />

          <h2>Skicka in</h2>
          <p>Kostnadsfritt.<br />
            Begränsat till fyra kartonger varannan vecka. Packa i valfritt emballage, ej större än en flyttkartong, och inte tyngre än 20kg.</p>
          <Form>
            <div key="inline-radio" className="mb-3">
              <Form.Check
                inline
                type="radio"
                id={`radio1`}
                name={`radio`}
                label={`1 st.`}
                value={`1`}
                onChange={handleRadioChange}
              />
              <Form.Check
                inline
                type="radio"
                id={`radio2`}
                name={`radio`}
                label={`2 st.`}
                value={`2`}
                onChange={handleRadioChange}
              />
              <Form.Check
                inline
                type="radio"
                id={`radio3`}
                name={`radio`}
                label={`3 st.`}
                value={`3`}
                onChange={handleRadioChange}
              />
              <Form.Check
                inline
                type="radio"
                id={`radio4`}
                name={`radio`}
                label={`4 st.`}
                value={`4`}
                onChange={handleRadioChange}
              />
            </div>
          </Form>

          <Row>
            <Col xs={5} lg={3}>
              <Button
                className="btn-footly"
                id="shippingLabel"
                name="shippingLabelButton"
                onClick={() => handleShippingLabel(kund)}
                disabled={shippingLabelDisabled}
              >
                Boka fraktetiketter
              </Button>
            </Col>
            <Col xs={4}>
              <span>{shippingLabelMessage} </span>
            </Col>
            <Col xs={3} />
            <span className="shippingLabel-not-selected"> {shippingLabelNotSelected}</span>

          </Row>
          <p>
            <br />
            {daysUntilShippingLabel}
            <br />
            <small>Ansvaret för att sakerna kommer fram hela till oss ligger hos dig som skickar in. Tänk
              på att max storlek är som en flyttkartong och 20kg. Skulle emballaget vara större
              och/eller tyngre så kommer du bli debiterad för det.</small>
          </p>
        </>
      )}
      <h4>{message}</h4>
    </div>
  );
}
export default Pickup;
