import React from "react";
import Table from "react-bootstrap/Table";
export default function Earnings(props) {
  return (
    <div>
      <h4>Återbäring</h4>
      <br />

      <Table responsive size="sm">
        <tbody>
          <tr key="earnings">
            <td>Återbäring</td>
            <td>{props.sum.toFixed(2)}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
