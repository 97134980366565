const CalculateEarnings = async (
  pricelist,
  products,
  returnedProducts,
  customerType,
) => {
  let sum = 0;
  let sumEarningsNotAlreadyPaid = 0;
  let sumEarningsPaid = 0;
  let sumEarningsTotal = 0;
  let soldItems = 0;
  let soldItemsPaid = 0;

  if (pricelist === null) {
    console.error("Ingen prislista för kund: ");
    return;
  }
  if (products.length !== 0) {
    for (const element of products) {
      let earnings;
      const fixedEarningPerItem = pricelist.CostPerSold > 0;

      if (customerType === "Privatperson") {
        earnings = await CalculateEarningPerItem(
          pricelist,
          fixedEarningPerItem,
          element.sumVat,
        );
        sum += element.sumVat;
        //Company
      } else {
        earnings = await CalculateEarningPerItem(
          pricelist,
          fixedEarningPerItem,
          element.sumExVat,
        );
        sum += element.sumExVat;
      }
      element.earnings = earnings;
      sumEarningsTotal += earnings;

      //If the earnings has not been paid to the customer
      if (element.paid === false) {
        sumEarningsNotAlreadyPaid += earnings;
        soldItems += element.quantity;
      } else {
        sumEarningsPaid += earnings;
        soldItemsPaid += element.quantity;
      }
    }
  }
  let returnedCostTotal, returnCostNotAlreadyPaid;

  var temp = CalculateReturnedProducts(returnedProducts);
  returnedCostTotal = temp.returnedCostTotal;
  returnCostNotAlreadyPaid = temp.returnCostNotAlreadyPaid;
  return {
    sum: sum,
    sumEarnings: sumEarningsNotAlreadyPaid,
    sumTotalEarnings: sumEarningsPaid,
    returnCostNotAlreadyPaid,
    soldItems,
  };
};

const CalculateEarningPerItem = async (pricelist, fixedEarningPerItem, sum) => {
  let earnings = 0;
  if (fixedEarningPerItem) {
    earnings = pricelist.CostPerSold;
  } else {
    if (sum <= pricelist.ProvisionAmount1) {
      earnings = sum * (pricelist.ProvisionPercent1 / 100);
    } else if (sum <= pricelist.ProvisionAmount2) {
      earnings =
        pricelist.ProvisionAmount1 * (pricelist.ProvisionPercent1 / 100);

      earnings +=
        (sum - pricelist.ProvisionAmount1) *
        (pricelist.ProvisionPercent2 / 100);
    } else {
      earnings =
        pricelist.ProvisionAmount1 * (pricelist.ProvisionPercent1 / 100);

      earnings +=
        (pricelist.ProvisionAmount2 - pricelist.ProvisionAmount1) *
        (pricelist.ProvisionPercent2 / 100);
      earnings +=
        (sum - pricelist.ProvisionAmount2) *
        (pricelist.ProvisionPercent3 / 100);
    }
  }
  return earnings;
};

const CalculateReturnedProducts = (returnedProducts) => {
  let returnCostNotAlreadyPaid = 0;
  let returnedCostTotal = 0;

  for (const rp of returnedProducts) {
    if (rp.paid !== true) returnCostNotAlreadyPaid += rp.earning;
    returnedCostTotal += rp.earning;
  }
  return { returnedCostTotal, returnCostNotAlreadyPaid };
};
const CalculateCostPerItem = async (numberOfItems, costPerItem) => {
  if (typeof costPerItem === "undefined") return 0;
  const calculatedCost = numberOfItems * costPerItem;
  return calculatedCost;
};

//This sums all that has been paid to the customer
const CalculateBalance = async (sumEarnings, cost) => {
  const saldo = sumEarnings - cost < 0 ? 0 : Math.round(sumEarnings - cost);
  return saldo;
};

export {
  CalculateEarnings,
  CalculateCostPerItem,
  CalculateBalance,
  CalculateEarningPerItem,
  CalculateReturnedProducts,
};
