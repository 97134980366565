import CallApi from "../components/CallApi";

const CreateDropOff = async (customerId, token, pickupCost = 0, dropOffDate = new Date(), handledDate=null ) => {
 
  let body = {
    KundId: customerId,
    dropOffDate: dropOffDate,
    pickupCost: pickupCost,
    handledDate: handledDate
  };
  let res = await CallApi("Post", `/dropoff`, body, token);
  return { dropOffId: res.data.message, dropOffDate: dropOffDate };
};

export { CreateDropOff };
