import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { TrashItems } from "./TrashItems";
import { Redirect } from "react-router-dom";
import FinishReturns from "./FinishReturns";

class RegistrationReturns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      products: [],
      dropOffId: null,
      showFinishResult: false,
      isLoading: false
    };
    // this.addItem = this.addItem.bind(this);
    // this.finishResult = this.finishResult.bind(this);
    // this.deleteItem = this.deleteItem.bind(this);
    // this.onKeyPress = this.onKeyPress.bind(this);
    // this.addText = this.addText.bind(this);
  }
  componentDidMount() {
    if (this.props.dropOffs.length)
      this.setState({ dropOffId: this.props.dropOffs[0].id });
  }

  delete = key => {
    this.props.delete(key);
  };
  addText = e => {
    e.preventDefault();
    let value = e.target.value;
    if (this._inputElementDescription.value !== "") {
      this._inputElementDescription.value =
        this._inputElementDescription.value + ", " + value;
    } else {
      this._inputElementDescription.value = value;
    }
    this._buttonElementAdd.focus();
  };
  onKeyPress = e => {
    const value = e.target.value;
    this._inputElementDescription.value = value;
  };

  handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      [name]: value
    });
  };
  addItem = e => {
    if (this._inputElementDescription.value !== "") {
      var newItem = {
        text: `${this._inputElementDescription.value}, `,
        amount: `${this._inputElementAmount.value} st,`,
        price: `${this._inputElementPrice.value} kr`,
        key: Date.now()
      };

      this.setState(prevState => {
        return {
          items: prevState.items.concat(newItem)
        };
      });

      this._inputElementDescription.value = "";
      // this._inputElementPrice.value = "";
      // this._inputElementAmount.value = "";
      e.preventDefault();
    }
  };
  deleteItem = key => {
    var filteredItems = this.state.items.filter(function(item) {
      return item.key !== key;
    });

    this.setState({
      items: filteredItems
    });
  };
  finishResult = () => {
    this.setState({ showFinishResult: true });
  };
  navigateToReturns = () => {
    return <Redirect to="/returns" />;
  };

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col>
            <h4>
              Namn på kunden: {this.props.förnamn} {this.props.efternamn},{" "}
              {this.props.kundtyp}{" "}
              <b>
                {this.props.kundtyp === "Privatperson" ? "0% " : "25% "}moms.
              </b>
            </h4>
            <h4>
              Inlämningstillfälle:{" "}
              {this.state.dropOffId !== null
                ? this.props.dropOffs.map(d => {
                    return (
                      new Date(d.dropOffDate).toISOString().split("T")[0] + " "
                    );
                  })
                : "Inget inlämningstillfälle registrerat!"}
            </h4>
            <br />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="form-group">
              <NumericTextBox
                format="0"
                ref={b => (this._inputElementAmount = b)}
                className="form-control"
                placeholder="Antal"
              />
            </div>
          </Col>
          <Col>
            <div className="form-group">
              <input
                ref={a => (this._inputElementDescription = a)}
                className="form-control"
                placeholder="Typ av vara"
              ></input>
            </div>
          </Col>
          <Col>
            <div className="form-group">
              <NumericTextBox
                ref={d => (this._inputElementPrice = d)}
                className="form-control"
                placeholder="Försäjningspris"
              ></NumericTextBox>
            </div>
          </Col>
          <Col>
            <div className="form-group">
              <Button
                ref={c => (this._buttonElementAdd = c)}
                className="btn-block btn-footly"
                id="addButton"
                name="addButton"
                type="submit"
                onClick={this.addItem}
              >
                Lägg till
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <div className="form-group">
              <label
                className="control-label"
                htmlFor="returAngerrattButton"
              ></label>
              <div className="">
                <Button
                  id="returAngerrattButton"
                  name="returAngerrattButton"
                  className="btn-info btn-block"
                  value="Retur - ångerrätt"
                  size="lg"
                  onClick={this.addText}
                >
                  Retur - ångerrätt
                </Button>
              </div>
            </div>
          </Col>
          <Col sm={6}>
            <div className="form-group">
              <label
                className="control-label"
                htmlFor="returReklamationsrattButton"
              ></label>
              <div className="">
                <Button
                  id="returReklamationsrattButton"
                  name="returReklamationsrattButton"
                  className="btn-info btn-block"
                  size="lg"
                  value="Retur - reklamation"
                  onClick={this.addText}
                >
                  Retur - reklamation
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <div className="form-group">
              <label
                className="control-label"
                htmlFor="saljsIgenButton"
              ></label>
              <div className="">
                <Button
                  id="saljsIgenButton"
                  name="saljsIgenButton"
                  className="btn-info btn-block"
                  value="Säljs igen"
                  size="lg"
                  onClick={this.addText}
                >
                  Säljs igen
                </Button>
              </div>
            </div>
          </Col>
          <Col sm={6}>
            <div className="form-group">
              <label
                className="control-label"
                htmlFor="valgorenhetButton"
              ></label>
              <div className="">
                <Button
                  id="valgorenhetButton"
                  name="valgorenhetButton"
                  className="btn-info btn-block"
                  size="lg"
                  value="Välgörenhet"
                  onClick={this.addText}
                >
                  Välgörenhet
                </Button>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <TrashItems items={this.state.items} deleteItem={this.deleteItem} />
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="form-group">
              <Button
                className="btn-block btn-footly"
                id="avslutaButton"
                name="avslutaButton"
                type="button"
                size="lg"
                onClick={!this.state.isLoading ? this.finishResult : null}
                disabled={this.state.showFinishResult}
              >
                {this.state.isLoading ? "Arbetar..." : " Avsluta returer"}
              </Button>
            </div>
            {this.state.showFinishResult ? (
              <FinishReturns
                kundId={this.props.kundId}
                kundtyp={this.props.kundtyp}
                pricelistId={this.props.pricelistId}
                items={this.state.items}
                email={this.props.email}
                accessToken={this.props.accessToken}
              />
            ) : null}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
export default RegistrationReturns;
