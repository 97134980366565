import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { IsLoadingButton } from "./LoadingSpinner";
import RegistrationForm from "./Administration/RegistrationForm/RegistrationForm";
import { getCustomerByCustomerNumber } from "../Helpers/CustomerInfo";

class ManageDropoff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      förnamn: "",
      efternamn: "",
      kundnr: "",
      kundId: "",
      dropOffs: null,
      pricelistId: "",
      showForm: false,
      status: "",
      isLoading: false,
    };
  }
  async componentDidMount() {
    const accessToken = await this.props.auth.getAccessTokenSilently();
    this.setState({ accessToken: accessToken });
  }

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      this.searchkundnummer(e);
    }
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
    });
  };

  searchkundnummer = (e) => {
    this.setState({ status: "", showForm: false, isLoading: true });
    e.preventDefault();
    this.getKundnr(this.state.kundnr);
  };

  getKundnr = async (kundnr) => {
    let response = await getCustomerByCustomerNumber(
      kundnr,
      this.state.accessToken,
    );
    console.log("Response: ", response.data);
    if (response.status === 404) {
      this.setState({ status: "Inlämningsnummer saknas", isLoading: false });
    } else if (response.status !== 200) {
      this.setState({ status: response.statusText, isLoading: false });
    } else {
      var kund = response.data.kund;
      console.log(
        `Kund är:  ${kund.id} ${kund.Kundnr} ${kund.Förnamn} ${kund.Efternamn}, pricelistId: ${kund.PricelistId}`,
      );
      let dropOffs = kund.DropOffs.filter((dropOff) => {
        return dropOff.handledDate === null;
      });
      this.setState({
        förnamn: kund.Förnamn,
        efternamn: kund.Efternamn,
        kundtyp: kund.Kundtyp,
        kundnr: kund.Kundnr,
        kundId: kund.id,
        email: kund.Epost,
        pricelistId: kund.PricelistId,
        dropOffs,
        showForm: true,
        isLoading: false,
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="call-to-action">
          <h2>Hantera inlämning</h2>
          <Row>
            <Col xs={6}>
              <div className="form-group">
                <input
                  id="kundnummerInput"
                  name="kundnr"
                  type="text"
                  value={this.state.kundnr}
                  onChange={this.handleChange}
                  placeholder="inlämningsnummer"
                  className="form-control"
                  onKeyPress={this.onKeyPress}
                />
              </div>
            </Col>
            <Col xs={2}>
              <Button
                className="btn-footly"
                id="searchButton"
                name="searchButton"
                onClick={this.searchkundnummer}
              >
                {this.state.isLoading && <IsLoadingButton />}
                Sök
              </Button>
            </Col>
            <Col xs={4}>
              <span className="status">{this.state.status}</span>
            </Col>
          </Row>
          {this.state.showForm ? <RegistrationForm {...this.state} /> : null}
        </div>
      </React.Fragment>
    );
  }
}

export default ManageDropoff;
