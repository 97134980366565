import React from "react";
// import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";

const NotLoggedIn = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  return (
    <>
      {isAuthenticated ? (
        "Autentiserad"
      ) : (
        <>
          {/* <h4>
            För att, se ditt saldo, begära en utbetalning eller registrera en
            inlämning behöver du
          </h4> */}
          <Button
            className="btn-footly"
            onClick={() => loginWithRedirect("login")}
          >
            LOGGA IN
          </Button>
          <br />
          <br />
          {/* <h4>För att lämna in för första gången behöver du</h4> */}
          <Button
            className="btn-footly-brown"
            onClick={() => loginWithRedirect({ action: 'signup' })}
          >
            SKAPA KONTO
          </Button>{" "}
        </>
      )}
    </>
  );
};
export { NotLoggedIn };
