import React from "react";
import { Row, Form, Col, Button } from "react-bootstrap";
import CallApi from "./CallApi";

class SearchKund extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {
      Epost: "",
      Förnamn: "",
      Efternamn: "",
      Kundnr: "",
      ShowResults: false,
      ShowEmpty: false,
    };
    this.state = this.initialState;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      [name]: value,
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    this.callAPI(this.state.Epost);
  }
  async callAPI(epost) {
    console.log(`/kund/email/${epost}`);
    const response = await CallApi(
      "Get",
      `/kund/email/${epost}`,
      "",
      await this.props.auth.getAccessTokenSilently()
    );
    console.log(response.data);
    if (response.status !== 404) {
      let kund = response.data.kund;
      console.log(`Kund är: ${kund.Kundnr} ${kund.Förnamn} ${kund.Efternamn}`);
      this.setState({
        Förnamn: kund.Förnamn,
        Efternamn: kund.Efternamn,
        Kundnr: kund.Kundnr,
        ShowResults: true,
        ShowEmpty: false,
      });
    } else {
      this.setState({
        ShowEmpty: true,
      });
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="call-to-action">
          <h2>Sök kund</h2>

          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col xs={9} lg={6}>
                <Form.Control
                  type="text"
                  name="Epost"
                  value={this.state.Epost}
                  onChange={this.handleChange}
                  placeholder="Epost"
                />
              </Col>
              <Col>
                <Button name="submit" type="submit" className="btn btn-primary">
                  Sök
                </Button>
              </Col>
            </Row>
          </Form>
          <br />
          {this.state.ShowResults ? <Results {...this.state} /> : null}
          {this.state.ShowEmpty ? this.noResult : null}
        </div>
      </React.Fragment>
    );
  }
  noResult = (<span>Ingen kund kunde hittas</span>);
}

const Results = (props) => {
  return (
    <Row>
      <Col>
        <h3>Kundnr: {props.Kundnr}</h3>
        <h4>
          Namn: {props.Förnamn} {props.Efternamn}
        </h4>
      </Col>
      <Col></Col>
    </Row>
  );
};
export default SearchKund;
