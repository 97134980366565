import React, { Component } from "react";
import { ListGroup } from "react-bootstrap";

export class TrashItems extends Component {
  removeItem(e) {
    this.props.deleteItem(e.id);
  }
  createItems(item) {
    return (
      <ListGroup.Item key={item.key}>
        {item.Antal}
        {item.Orsak}

        <button
          type="button"
          className="close"
          onClick={() => {
            this.removeItem(item);
          }}
        >
          &times;
        </button>
      </ListGroup.Item>
    );
  }
  render() {
    return (
      <ListGroup className="trash-items">
        {this.props.items.map((item) => {
          return (
            <ListGroup.Item key={item.id}>
              {item.Antal} st, {item.Orsak}
              <button
                type="button"
                className="close"
                onClick={() => {
                  this.removeItem(item);
                }}
              >
                &times;
              </button>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    );
  }
}
export default TrashItems;
