import React, { useRef, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";

const AmountButtons = (props) => {
  const _amount = useRef(props.amount);

  useEffect(() => {
    _amount.current.value = props.amount;
  }, [props.amount]);
  const addAmount = (e) => {
    e.preventDefault();
    let value = e.target.value;
    _amount.current.value = value;
    props.handleAmountChanged(_amount.current.value);
  };
  const handleChange = (event) => {
    const { value } = event.target;
    props.handleAmountChanged(value);
  };

  return (
    <>
      <Row className="align-items-baseline">
        <Col sm="2">
          <h4>Antal:</h4>
        </Col>
        <Col>
          <div className="form-group">
            <input
              ref={_amount}
              className="form-control"
              placeholder="Antal"
              onChange={handleChange}
              onKeyPress={props.onKeyPress}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            id="oneButton"
            name="oneButton"
            className="btn-info btn-block control-label"
            size="sm"
            value="1"
            onClick={addAmount}
          >
            1
          </Button>
        </Col>
        <Col>
          <Button
            id="twoButton"
            name="twoButton"
            className="btn-info btn-block"
            size="sm"
            value="2"
            onClick={addAmount}
          >
            2
          </Button>
        </Col>
        <Col>
          <Button
            id="threeButton"
            name="threeButton"
            className="btn-info btn-block"
            size="sm"
            value="3"
            onClick={addAmount}
          >
            3
          </Button>
        </Col>
        <Col>
          <Button
            id="fourButton"
            name="fourButton"
            className="btn-info btn-block"
            size="sm"
            value="4"
            onClick={addAmount}
          >
            4
          </Button>
        </Col>
        <Col>
          <Button
            id="fiveButton"
            name="fiveButton"
            className="btn-info btn-block"
            size="sm"
            value="5"
            onClick={addAmount}
          >
            5
          </Button>
        </Col>
      </Row>
      <Row className="row-margin">
        <Col>
          <Button
            id="sixButton"
            name="sixButton"
            className="btn-info btn-block"
            size="sm"
            value="6"
            onClick={addAmount}
          >
            6
          </Button>
        </Col>
        <Col>
          <Button
            id="sevenButton"
            name="sevenButton"
            className="btn-info btn-block"
            size="sm"
            value="7"
            onClick={addAmount}
          >
            7
          </Button>
        </Col>
        <Col>
          <Button
            id="eightButton"
            name="fiveButton"
            className="btn-info btn-block"
            size="sm"
            value="8"
            onClick={addAmount}
          >
            8
          </Button>
        </Col>
        <Col>
          <Button
            id="nineButton"
            name="fiveButton"
            className="btn-info btn-block"
            size="sm"
            value="9"
            onClick={addAmount}
          >
            9
          </Button>
        </Col>
        <Col>
          <Button
            id="tenButton"
            name="tenButton"
            className="btn-info btn-block"
            size="sm"
            value="10"
            onClick={addAmount}
          >
            10
          </Button>
        </Col>
      </Row>
    </>
  );
};
export default AmountButtons;
