import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import deliverylogo from "../icon-delivery.png";
import idcardlogo from "../icon-id-card.png";
import accountlogo from "../icon-account.png";

function StartIcons() {
  return (
    <>
      <Row>
        <Col lg={4} xs={12}>
          <Link to="/myaccount">
            <img
              alt="accountlogo"
              className="start-icons mx-auto d-block fade-in-bottom"
              src={accountlogo}
            />
            <div className="text-center fade-in-bottom">SALDO</div>
          </Link>
        </Col>
        <Col lg={4} xs={12}>
          <Link to="/pickup">
            <img
              alt="deliverylogo"
              className="start-icons mx-auto d-block fade-in-bottom"
              src={deliverylogo}
            />
            <div className="text-center fade-in-bottom">LÄMNA IN</div>
          </Link>
        </Col>

       
        <Col lg={4} xs={12}>
          <Link to="/profile">
            <img
              alt="checkboxlogo"
              className="start-icons mx-auto d-block fade-in-bottom"
              src={idcardlogo}
            />
            <div className="text-center fade-in-bottom">UPPGIFTER</div>{" "}
          </Link>
        </Col>
      </Row>
    </>
  );
}
export { StartIcons };
