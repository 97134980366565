import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { GeneratePayment } from "../Helpers/GeneratePayment";
import ConfirmationDialog from "../Helpers/PaymentConfirmationDialog";
import SwishPaymentDialog from "../Helpers/SwishPaymentDialog";

export default class Payment extends React.Component {
  state = {
    result: "",
    paymentEnabled: true,
    dialogVisible: false,
    swishPaymentDialogVisible: false,
  };

  handleClose = () => {
    this.setState({
      dialogVisible: false,
      swishPaymentDialogVisible: false,
    });
  };

  toggleConfirmPaymentDialog = (paymentType) => {
    let header = "";
    let bodyText = "";
    let abort = false;
    switch (paymentType) {
      case "p":
        if (
          this.props.accountNumber.trim().length === 0 &&
          this.props.customerType === "Privatperson"
        ) {
          header = "Fyll i kontonummer";
          bodyText =
            "Du måste fylla i ditt kontonummer för att kunna göra en utbetalning. <br/><a  href='/profile'>Gå till min profil</a>";
          abort = true;
          break;
        } else header = "UTBETALNING";
        bodyText = "Är du säker att du vill betala ut dina pengar?";
        break;
      case "v":
        header = "DONERA VÄLGÖRENHET";
        bodyText =
          "Är du säker att du vill donera dina pengar till välgörenhet?";
        break;
      case "f":
        header = "DONERA FOOTLY";
        bodyText = "Är du säker att du vill donera dina pengar till Footly?";
        break;
      case "s":
        header = "Få utbetalning via Swish";
        break;
      default:
        break;
    }
    paymentType === "s"
      ? this.setState({ swishPaymentDialogVisible: true })
      : this.setState({ dialogVisible: true });

    this.setState({
      paymentType: paymentType,
      header: header,
      bodyText: bodyText,
      abort: abort,
    });
  };
  handleSubmit = (mobilnummer) => {
    this.RequestPayment(this.state.paymentType, mobilnummer);
    this.setState({ dialogVisible: false, swishPaymentDialogVisible: false });
  };

  RequestPayment = async (sender, mobilnummer) => {
    this.setState({ paymentEnabled: false });
    const token = this.props.accessToken;

    var res = await GeneratePayment(
      this.props.balance,
      this.props.accountNumber,
      this.props.email,
      token,
      sender,
      this.props.customerType,
      this.props.customerNumber,
      this.props.name,
      this.props.customerId,
      this.props.dropOffCost,
      this.props.paymentDate,
      this.props.customerPaymentDate,
      this.props.soldInSello,
      this.props.doljForsaljningspris,
      mobilnummer,
      this.props.purchases,
    );
    let result = "";
    let moneyInAccount = "";
    if (res.status === 200) {
      switch (sender) {
        case "p":
          if (this.props.customerType === "Privatperson") {
            moneyInAccount =
              this.props.customerPaymentDate.toISOString().slice(0, 10) + ".";
            result =
              "Utbetalning är begärd, pengarna landar på ditt konto senast ";
          } else {
            result = "Faktureringsunderlag är skapat.";
          }
          break;

        case "v":
          result = "Tack för att du donerat pengarna till välgörenhet!";
          break;
        case "f":
          result = "Tack för att du donerat pengarna till Footly!";
          break;
        case "s":
          result = "Utbetalning via Swish är begärd.";
          break;
        default:
      }
    } else {
      result = "Något gick fel, kontakta oss på Footly";
    }
    const confirmation = " Se mail för bekräftelse.";
    this.props.reload(this.props.id, result, confirmation, moneyInAccount);
    this.setState({ paymentEnabled: true });
  };

  render() {
    if (Math.round(this.props.balance) > 0) {
      let label =
        this.props.customerType === "Privatperson"
          ? "UTBETALNING"
          : "FAKTURERINGSUNDERLAG";
      return (
        <div className="payment">
          {this.state.dialogVisible ? (
            <ConfirmationDialog
              isOpen={this.state.dialogVisible}
              handleClose={this.handleClose}
              handleSubmit={this.handleSubmit}
              header={this.state.header}
              bodyText={this.state.bodyText}
              abort={this.state.abort}
            />
          ) : null}

          {this.state.swishPaymentDialogVisible ? (
            <SwishPaymentDialog
              isOpen={this.state.swishPaymentDialogVisible}
              handleClose={this.handleClose}
              handleSubmit={this.handleSubmit}
              header={this.state.header}
              abort={this.state.abort}
              customerNumber={this.props.customerNumber}
              customerId={this.props.customerId}
              sum={this.props.balance}
            />
          ) : null}
          <Row>
            <Col>
              {/* <h4>Utbetalning</h4> */}
              <br />
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <Button
                className="btn-block btn-lg"
                onClick={() => {
                  if (this.props.customerType === "Privatperson") {
                    this.toggleConfirmPaymentDialog("s");
                  } else {
                    this.toggleConfirmPaymentDialog("p");
                  }
                }}
                enabled={this.state.paymentEnabled.toString()}
              >
                {label}
              </Button>
            </Col>
            <Col sm={4}>
              <Button
                className="btn-block btn-lg"
                onClick={() => {
                  this.toggleConfirmPaymentDialog("v");
                }}
                enabled={this.state.paymentEnabled.toString()}
              >
                DONERA VÄLGÖRENHET
              </Button>
            </Col>
            <Col sm={4}>
              <Button
                className="btn-block btn-lg"
                onClick={() => {
                  this.toggleConfirmPaymentDialog("f");
                }}
                enabled={this.state.paymentEnabled.toString()}
              >
                DONERA FOOTLY
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className="some-margin"></Col>
          </Row>
          <p>
            Ditt saldo som ska utbetalas är: {Math.round(this.props.balance)}{" "}
            kr.
          </p>
        </div>
      );
    } else {
      return <h4>{this.state.result}</h4>;
    }
  }
}
