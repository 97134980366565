import React from "react";
import Table from "react-bootstrap/Table";

export default function DropOffs(props) {
  const { dropoffs, costPerItem } = props;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const adjustedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    return adjustedDate.toISOString().split("T")[0];
  };

  return (
    <div>
      <br />
      <Table responsive size="sm">
        <thead>
          <tr>
            <th>Datum</th>
            <th>Antal</th>
            <th>Hanteringskost.</th>
          </tr>
        </thead>
        <tbody>
          {dropoffs.map((dropoff) => {
            const { id, dropOffDate, noItems, pickupCost, Scrappeds, paid } = dropoff;

            if (paid) {
              return null;
            }

            const cost = pickupCost === null || pickupCost === 0 ? noItems * costPerItem : pickupCost;

            return (
              <React.Fragment key={id}>
                <tr>
                  <td>{formatDate(dropOffDate)}</td>
                  <td>{noItems}</td>
                  <td>{`${cost.toFixed(2)} kr`}</td>
                </tr>
                {Scrappeds.length > 0 && (
                  <tr>
                    <td colSpan="3">
                      <b>Skänkta: </b>
                      <br />
                      {Scrappeds.map((scrap, index, array) => (
                        <span key={index}>
                          {`${scrap.Antal} st. ${scrap.Orsak}.`}
                          {index !== array.length - 1 && <br />}
                        </span>
                      ))}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}
