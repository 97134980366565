import React from "react";
import { GridCell } from "@progress/kendo-react-grid";

export function CommandCell({
  edit,
  remove,
  add,
  update,
  discard,
  cancel,
  editField
}) {
  return class extends GridCell {
    render() {
      const { dataItem } = this.props;
      const inEdit = dataItem[editField];
      const isNewItem = dataItem.id === undefined;
      return inEdit ? (
        <td className="k-command-cell">
          <span
            className="k-grid-save-command k-icon k-i-save"
            onClick={() => (isNewItem ? add(dataItem) : update(dataItem))}
          />
          {/* {isNewItem ? "Add" : "Update"} */}

          <span
            className="k-icon k-i-cancel k-grid-cancel-command"
            onClick={() => cancel(dataItem)}
          />
        </td>
      ) : (
        <td>
          <span
            className="k-icon k-i-close "
            onClick={() =>
              window.confirm("Ta bort: " + dataItem.Name) && remove(dataItem)
            }
          />
        </td>
      );
    }
  };
}
