import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { Link } from "react-router-dom";
import { getCustomersWithEarnings } from "../Helpers/CustomerInfo";
import { LoadingSpinner } from "./LoadingSpinner";

const columns = [
  {
    dataField: "KundId",
    text: "",
    formatter: (cellContent, row) => (
      <Link
        to={{
          pathname: `/profile/${row.KundId}`,
          state: { id: row.KundId },
        }}
      >
        <i className="material-icons">edit</i>
      </Link>
    ),
    headerStyle: () => {
      return { width: "40px", textAlign: "center" };
    },
  },
  {
    dataField: "Kundnr",
    text: "Inl.nr.",
    sort: true,
  },
  {
    dataField: "Förnamn",
    text: "Förnamn",
  },
  {
    dataField: "Efternamn",
    text: "Efternamn",
    sort: true,
  },
  {
    dataField: "Epost",
    text: "Epost",
    sort: true,
  },
  {
    dataField: "Kundtyp",
    text: "Kundtyp",
    sort: true,
    formatter: (cellContent, row) =>
      cellContent === "Privatperson" ? "Privat" : "Företag",
  },
  {
    dataField: "Prislista",
    text: "Prislista",
    sort: true,
    formatter: (cellContent, row) => (isNaN(cellContent) ? cellContent : ""),
  },
  {
    dataField: "Nyhetsbrev",
    text: "Nyh.brev",
    sort: true,
    formatter: (cellContent, row) => (cellContent ? "Ja" : "Nej"),
  },
  {
    dataField: "Earnings",
    text: "Saldo",
    sort: true,
    formatter: (cellContent, row) =>
      !isNaN(cellContent) ? Math.round(cellContent) + " kr" : "",
  },
];

function KundTable(props) {
  const [kunder, setKunder] = useState([]);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const getCustomer = async (token) => {
    var customers = await getCustomersWithEarnings(token);
    if (customers.data === undefined) {
      setMessage(customers.message);
      return;
    }
    return customers.data.kunder;
  };

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = await props.auth.getAccessTokenSilently();
      let kunder = await getCustomer(accessToken);
      setKunder(kunder);
      setIsLoading(false);
    };
    if (kunder.length === 0) fetchData();
  });

  return (
    <div className="call-to-action">
      <h2>Kunder</h2>
      <h4>{message}</h4>
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <BootstrapTable
          bootstrap4
          responsive
          keyField="KundId"
          data={kunder}
          columns={columns}
          pagination={paginationFactory({
            sizePerPageList: [
              { text: "All", value: kunder.length },
              { text: "50", value: 50 },
              { text: "100", value: 100 },
              { text: "500", value: 500 },
            ],
          })}
        />
      )}
    </div>
  );
}
export default KundTable;
